import { useStore } from '../../helpers/use-store';
import { Flex, TextComponent } from '@monorepo/react-components';
import { Link } from 'react-router-dom';

import './user-verification-banner.scss';

const UserVerificationBanner = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  if (!userStore.verificationRequired) {
    return null;
  }

  return (
    <Flex
      justify="center"
      className="user-verification-banner"
      align="center"
      gap={5}
    >
      <TextComponent level={6} responsive={false}>
        Action Required -
      </TextComponent>
      <TextComponent level={6} responsive={false}>
        <Link to={'/profile/kyc'}>Click here to complete KYC</Link>
      </TextComponent>
    </Flex>
  );
};

export default UserVerificationBanner;
