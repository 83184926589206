import {
  CreditCardOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useResponsiveness } from '@monorepo/client-common';
import {
  AnalyticsEventTypes,
  ButtonText,
  FeatureFlagNames,
  PagePath,
} from '@monorepo/types';
import { Avatar, Dropdown, Flex, Layout, MenuProps } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../../helpers/use-store';
import { useFeatureFlags } from '../../../hooks/use-feature-flags';
import VerificationRequiredButton from '../../verification-required-button/verification-required-button';
import NavigationDrawer from '../navigation-menu/navigation-drawer/navigation-drawer';
import HubCredits from '../../../features/credits/hub-credits';
import gtmService from '../../../services/analytics/analytics';
import Logo from '../../logo/logo';
import { Icons, IconsNames } from '@monorepo/icons';
import NotificationsCenter from '../../notifications-center/notifications-center';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import ThemeSwitch from '../../../features/theme-switch/theme-switch';

import './header.scss';

const Header = () => {
  const {
    dataStore: { applicationConfigStore, userStore },
  } = useStore();
  const { isFeatureEnabled } = useFeatureFlags();
  const location = useLocation();

  const navigate = useNavigate();

  const paymentsDisabled =
    !isFeatureEnabled(FeatureFlagNames.DisablePayments) ||
    applicationConfigStore.applicationConfig.isBlacklisted;

  const handleNewAccountClick = () => {
    gtmService.sendEvent({
      event: AnalyticsEventTypes.NewAccountClick,
      params: {
        button_text: ButtonText.NewAccount,
      },
    });
    navigate(PagePath.NewAccount);
  };

  const handleLogout = () => {
    gtmService.sendEvent({
      event: AnalyticsEventTypes.Logout,
      params: {
        user_id: userStore.currentUser.userId,
      },
    });
    userStore.logout();
  };

  const items: MenuProps['items'] = [
    {
      label: 'Profile',
      icon: <UserOutlined />,
      key: '1',
      onClick: () => navigate(PagePath.Profile),
    },
    {
      label: ButtonText.NewAccount,
      key: '2',
      disabled: userStore.verificationRequired || paymentsDisabled,
      icon: <CreditCardOutlined />,
      onClick: handleNewAccountClick,
    },
    {
      label: 'Logout',
      key: '3',
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  const { isMobile, isDesktopXxl } = useResponsiveness();

  return (
    <Layout.Header
      id={'header'}
      className={classNames('header', { 'header--minimal': !isDesktopXxl })}
    >
      <Flex
        align="center"
        justify="space-between"
        className={'header--content'}
      >
        {isDesktopXxl ? (
          <Breadcrumbs />
        ) : (
          <Flex align="center" gap={15}>
            {!isMobile && <Logo />}
            <NavigationDrawer />
          </Flex>
        )}

        <Flex gap={isDesktopXxl ? 25 : 4} align={'center'}>
          {isDesktopXxl && <ThemeSwitch />}
          <VerificationRequiredButton
            style={{ fontWeight: 'bold' }}
            disabled={location.pathname === PagePath.NewAccount}
            icon={<Icons iconName={IconsNames.Bolt} />}
            key={'verification-btn'}
          >
            {ButtonText.NewOrder}
          </VerificationRequiredButton>

          <Flex align="center">
            <HubCredits />
            <NotificationsCenter />
          </Flex>
          <Dropdown trigger={['click']} menu={{ items }}>
            <Avatar
              className="header--avatar"
              src={userStore.currentUser.profileUrl}
              icon={
                <Icons
                  className="header--avatar-icon"
                  iconName={IconsNames.People}
                />
              }
            />
          </Dropdown>
        </Flex>
      </Flex>
    </Layout.Header>
  );
};

export default observer(Header);
