import React, { FunctionComponent } from 'react';
import { Application } from '@monorepo/types';
import fiversSpinner from './fivers-spinner.json';
import ttpSpinner from './ttp-spinner.json';
import Lottie from 'lottie-react';

interface Props {
  application: Application;
}

export const BrandSpinner: FunctionComponent<Props> = ({ application }) => {
  return (
    <div className="brand-spinner">
      <Lottie
        autoPlay
        animationData={
          application === Application.TTP ? ttpSpinner : fiversSpinner
        }
      />
    </div>
  );
};
