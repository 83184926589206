import { Application } from '@monorepo/types';
import { BrandSpinner } from '@monorepo/react-components';

import './spinners.scss';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

export const AppLoadingSpinner = () => {
  return (
    <div className="app-loading-spinner">
      <BrandSpinner application={project} />
    </div>
  );
};
