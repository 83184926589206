import isAlpha from 'validator/lib/isAlpha';

export const PASSWORD_MIN_LENGTH = 8;
export const STRING_MIN_FIELD_LENGTH = 2;
export const STRING_FIELD_LENGTH = 60;

export const formNameValidation = () => {
  return {
    required: 'Name is required',
    maxLength: formMaxLengthValidation(
      `Name must be less than ${STRING_FIELD_LENGTH} characters`
    ),
    minLength: formMinLengthValidation(
      `Name must be at least ${STRING_MIN_FIELD_LENGTH} characters`
    ),
    validate: (name: string) => {
      const splitName = name.split('');

      if (
        (splitName.length && splitName[0] === ' ') ||
        splitName[splitName.length - 1] === ' '
      ) {
        return 'Cannot start or end with a space';
      }

      if (name.indexOf('  ') !== -1) {
        return 'Cannot have multiple spaces in a row';
      }

      return (
        isAlpha(name, 'en-US', { ignore: ' ' }) ||
        'Name must contain only English letters'
      );
    },
  };
};

export const formUserNameValidation = () => {
  return {
    maxLength: formMaxLengthValidation(
      `Username must be less than ${STRING_FIELD_LENGTH} characters`
    ),
    minLength: formMinLengthValidation(
      `Username must be at least ${STRING_MIN_FIELD_LENGTH} characters`
    ),
    validate: (username: string) => {
      const isValid = validateUsername(username);

      return (
        isValid ||
        'Username must start with a letter and may only contain letters, numbers, underscores, or hyphens. No consecutive underscores or hyphens are allowed.'
      );
    },
  };
};

export const formPasswordValidation = () => {
  return {
    required: 'Password is required',
    minLength: {
      value: PASSWORD_MIN_LENGTH,
      message: `Password must be at least ${PASSWORD_MIN_LENGTH} characters`,
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[\d])(?=.*\W).+$/,
      message:
        'Password must contain at least one uppercase letter and one symbol',
    },
  };
};

export const formPhoneValidation = () => {
  return {
    required: 'Phone number is required',
    pattern: {
      value: /^[0-9+]{7,15}$/,
      message: 'Invalid phone number',
    },
  };
};

export const formEmailValidation = () => {
  return {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address',
    },
  };
};

export const formMaxLengthValidation = (
  message: string,
  maxFormInputLength: number = STRING_FIELD_LENGTH
) => {
  return {
    value: maxFormInputLength,
    message,
  };
};

export const formMinLengthValidation = (
  message: string,
  minFormInputLength: number = STRING_MIN_FIELD_LENGTH
) => {
  return {
    value: minFormInputLength,
    message,
  };
};

export const validateUsername = (username: string) => {
  const usernameRegex = /^[a-zA-Z][a-zA-Z0-9_-]{2,}$/;
  const consecutiveSpecialCharRegex = /[-_]{2,}/;

  return (
    usernameRegex.test(username) && !consecutiveSpecialCharRegex.test(username)
  );
};
