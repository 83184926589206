import {
  Card,
  Flex,
  ProgressSlider,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import DashboardTooltip from '../../dashboard-common/dashboard-tooltip/dashboard-tooltip';
import { useResponsiveness } from '@monorepo/client-common';
import { useStore } from '../../../../helpers/use-store';
import { Application, ThemeColors } from '@monorepo/types';
import { useColor } from '../../../../hooks';
import AccountMidnightMark from '../account-midnight-mark/account-midnight-mark';

import './progress-cards.scss';

export enum DailyLimitType {
  DailyLoss = 'daily-loss',
  DailyPause = 'daily-pause',
}

const title = {
  [DailyLimitType.DailyLoss]: 'Daily Loss Level',
  [DailyLimitType.DailyPause]: 'Daily Pause Level',
};
const tooltipTTP = 'Valid Daily Profits and Loss';
const tooltip5ers = {
  [DailyLimitType.DailyLoss]:
    'of the highest between the starting equity or balance of the day. Going below it will lead to account termination. NOTE: this is an approximation; the value may be higher.',
  [DailyLimitType.DailyPause]:
    'of the highest between the starting equity or balance of the day. Going below it will lead to account pause for the day. NOTE: this is an approximation; the value may be higher.',
};

interface Props {
  programId: string;
  midnightBalance?: number;
  midnightEquity?: number;
  equity: number;
  baseBalanceWithCredits: number;
  allowedDailyLosses: number;
  application: Application;
  cardBgColor?: ThemeColors;
  fontColorReverse?: boolean;
  showMidnight?: boolean;
  accountId: string;
}

const DailyLimitLevel: FunctionComponent<Props> = ({
  programId,
  midnightBalance,
  midnightEquity,
  equity,
  baseBalanceWithCredits,
  allowedDailyLosses,
  application,
  cardBgColor = ThemeColors.colorSuccess,
  fontColorReverse = true,
  showMidnight = true,
  accountId,
}) => {
  const { isMobile } = useResponsiveness();
  const {
    dataStore: { programStore },
  } = useStore();
  const program = programStore.get(programId);
  const { getColor } = useColor();
  const cardBackgroundColor = getColor(cardBgColor);

  const dailyLossPercentage = program?.config?.dailyLossPercentage;
  const dailyPausePercentage = program?.config?.dailyPausePercentage;

  const maxDailyLoss = dailyLossPercentage || dailyPausePercentage || 0;

  const dailyLimitType = dailyLossPercentage
    ? DailyLimitType.DailyLoss
    : DailyLimitType.DailyPause;

  const stopOut =
    (program?.config.buyingPower || 0) - (program?.config.maxLoss || 0);

  const allowedLoss = allowedDailyLosses * -1;

  const getMaxSnapshot = () => {
    if (!midnightBalance || !midnightEquity) {
      return baseBalanceWithCredits;
    }

    return Math.max(midnightBalance, midnightEquity);
  };

  const getBaseEquity = () => {
    if (!midnightBalance || !midnightEquity) {
      return baseBalanceWithCredits;
    }

    return equity;
  };

  const dailyLimitLevel = getMaxSnapshot() - allowedLoss;

  const lossLeft =
    getBaseEquity() - dailyLimitLevel > 0
      ? getBaseEquity() - dailyLimitLevel
      : 0;

  let tooltipText = '';

  if (application === Application.THE5ERS) {
    tooltipText = `-${maxDailyLoss}% ${tooltip5ers[dailyLimitType]}`;
  } else {
    tooltipText = tooltipTTP;
  }

  const minValue = getMaxSnapshot() - allowedLoss;
  const minValueToDisplay = minValue > stopOut ? minValue : stopOut;

  return (
    <Card
      hoverable={false}
      style={{
        backgroundColor: cardBackgroundColor,
        borderColor: cardBackgroundColor,
        color: fontColorReverse
          ? getColor(ThemeColors.colorReverse)
          : 'inherit',
      }}
      className={classNames('progress-cards', 'progress-cards__card')}
    >
      <Flex vertical gap={isMobile ? 3 : 0}>
        <Flex justify={'space-between'}>
          <TextComponent weight={400} level={isMobile ? 5 : 3}>
            {title[dailyLimitType]}
          </TextComponent>
          <DashboardTooltip tooltipText={tooltipText} />
        </Flex>

        <Flex justify={'space-between'} align={'center'}>
          <Flex align="baseline" gap={isMobile ? 4 : 8}>
            <TextComponent level={isMobile ? 3 : 2} weight={600}>
              <Statistic prefix={'$'} value={lossLeft} precision={0} />
            </TextComponent>

            <TextComponent weight={400} level={isMobile ? 3 : 2}>
              left
            </TextComponent>
          </Flex>
        </Flex>

        <Flex vertical>
          <ProgressSlider
            disabled
            value={equity}
            min={dailyLimitLevel}
            max={getMaxSnapshot()}
            primaryHandle={false}
            tooltip={{
              formatter: (value) => (
                <Statistic prefix={'$'} value={value} precision={0} />
              ),
            }}
          />

          <Flex justify="space-between">
            <Flex vertical>
              <TextComponent level={8} weight={600}>
                <Statistic
                  prefix={'$'}
                  value={minValueToDisplay}
                  precision={0}
                />
              </TextComponent>
              <TextComponent level={8}>{title[dailyLimitType]}</TextComponent>
            </Flex>

            <Flex vertical align="end">
              <TextComponent level={8} weight={600}>
                <Statistic
                  prefix={'$'}
                  value={getMaxSnapshot()}
                  precision={0}
                />
              </TextComponent>

              {showMidnight && (
                <AccountMidnightMark
                  accountId={accountId}
                  balance={midnightBalance || baseBalanceWithCredits}
                  equity={midnightEquity || baseBalanceWithCredits}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
export default DailyLimitLevel;
