import React, { FunctionComponent, useEffect, useState } from 'react';
import { Layout as AntdLayout } from 'antd';
import { observer } from 'mobx-react-lite';
import Header from './header/header';
import { TextComponent, Flex } from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { useResponsiveness } from '@monorepo/client-common';
import classNames from 'classnames';
import { Content, Footer } from 'antd/es/layout/layout';
import NavigationSider from './navigation-menu/navigation-sider/navigation-sider';
import { AnalyticsEventTypes } from '@monorepo/types';
import gtmService from '../../services/analytics/analytics';
import Breadcrumbs from './breadcrumbs/breadcrumbs';
import HeaderBanner from './header-banner/header-banner';
import { useLocation } from 'react-router-dom';
import UserVerificationBanner from '../../features/user-verification-banner/user-verification-banner';

import './layout.scss';

interface Props {
  children: React.ReactNode;
}

const MINIMUM_HEADER = 100;

const Layout: FunctionComponent<Props> = ({ children }) => {
  const {
    dataStore: { applicationConfigStore },
  } = useStore();
  const location = useLocation();
  const { isDesktopXxl, isMobile } = useResponsiveness();

  const [lastScrollTop, setLastScrollTop] = useState(0);

  const { website, banners, name } = applicationConfigStore.applicationConfig;

  useEffect(() => {
    const handleScroll = () => {
      const content = document.getElementById('main-content');
      const layout = document.getElementById('layout');
      if (layout) {
        const currentScrollTop = content?.scrollTop || 0;
        if (currentScrollTop > lastScrollTop) {
          layout.classList.add('hide-header');
        } else if (
          currentScrollTop + MINIMUM_HEADER / 2 < lastScrollTop ||
          currentScrollTop < MINIMUM_HEADER
        ) {
          layout.classList.remove('hide-header');
        }
        setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
      }
    };

    document
      .getElementById('main-content')
      ?.addEventListener('scroll', handleScroll);
    return () => {
      document
        .getElementById('main-content')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    gtmService.sendEvent({
      event: AnalyticsEventTypes.PageView,
      params: {
        title: location.pathname,
        location: window.location.href,
      },
    });
  }, [location.pathname]);

  return (
    <AntdLayout className="layout" id={'layout'} hasSider={isDesktopXxl}>
      {isDesktopXxl && <NavigationSider />}
      <AntdLayout>
        <Header />
        <UserVerificationBanner />
        <HeaderBanner banners={banners} />
        <Content className="content">
          <Flex
            gap={24}
            flex={1}
            className="main-content"
            id={'main-content'}
            vertical
          >
            {!isDesktopXxl && <Breadcrumbs />}
            {children}
          </Flex>
          <Footer
            className={classNames('footer', {
              footer__minimal: isMobile,
            })}
          >
            <Flex
              className="footer-container"
              gap={8}
              justify={'center'}
              align={'center'}
            >
              <TextComponent
                level={7}
                className="footer-container__copyright-link"
              >
                <a href={website} rel="noreferrer" target="_blank">
                  {name}
                </a>
              </TextComponent>
              <TextComponent level={7}>{`/`}</TextComponent>
              <TextComponent level={7}>
                COPYRIGHT © {new Date().getFullYear()}
              </TextComponent>
            </Flex>
          </Footer>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export default observer(Layout);
