import React, { FunctionComponent, useEffect, useState } from 'react';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { Flex, Table, TextComponent } from '@monorepo/react-components';
import { MidnightValue } from '@monorepo/types';
import { useStore } from '../../../../helpers/use-store';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';

interface Props {
  accountId: string;
}

const AccountMidnightMarkTable: FunctionComponent<Props> = ({ accountId }) => {
  const {
    dataStore: { accountStore },
  } = useStore();

  const { isLoading, updateLoadingState } = useLoading();
  const [midnightValues, setMidnightValues] = useState<MidnightValue[]>([]);

  const columns: ColumnsType<MidnightValue> = [
    {
      title: 'Date',
      key: 'date',
      render: (_, record) => dayjs(record.date).format('DD MMM YYYY'),
    },
    {
      title: 'Midnight Balance',
      key: 'balance',
      render: (_, record) => record.balance,
    },
    {
      title: 'Midnight Equity',
      key: 'equity',
      render: (_, record) => record.equity,
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getMidnightHistory(accountId);
        setMidnightValues(result);
      } catch (e) {
        console.error('Failed getting midnight values', e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [accountId]);

  return (
    <Flex className="account-midnight-mark__table" vertical gap={5}>
      <TextComponent>Midnight Values (Last 60 days)</TextComponent>
      <Table
        rowHoverable={false}
        size="small"
        loading={isLoading}
        rowKey={(record: MidnightValue) => record._id}
        bordered={false}
        columns={columns}
        dataSource={midnightValues}
      />
    </Flex>
  );
};

export default AccountMidnightMarkTable;
