import { FunctionComponent, useEffect, useState } from 'react';
import { Card, Col, Flex, Row } from '@monorepo/react-components';
import {
  Account,
  AccountStatus,
  AccountType,
  Application,
} from '@monorepo/types';
import ClosedPositionsTable from '../../positions/closed-positions';
import OpenPositions from '../../positions/open-positions';
import AccountBalance from '../dashboard-common/account-balance/account-balance';
import { useStore } from '../../../helpers/use-store';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import AccountInfo from '../dashboard-common/account-info/account-info';
import ProfitTarget from '../dashboard-common-new/progress-cards/profit-target';
import ProfitWithdrawal from '../dashboard-common-new/progress-cards/profit-withdrawal';
import Violations from '../dashboard-common-new/progress-cards/violations';
import DailyLimitLevel from '../dashboard-common-new/progress-cards/daily-limit-level';
import InactiveDays, {
  DashboardCardType,
} from '../dashboard-common-new/cards-wrapper/inactive-days';
import ProfitableDays from '../dashboard-common-new/cards-wrapper/profitable-days';
import AccountRules from '../dashboard-common-new/account-rules/account-rules';
import classNames from 'classnames';
import AdvancedStatisticsFivers from './advanced-statistics-5ers';

interface Props {
  account: Account;
}

const APPLICATION_TYPE = Application.THE5ERS;

const FiversDashboardNew: FunctionComponent<Props> = ({ account }) => {
  const {
    dataStore: { accountStore, programStore },
  } = useStore();
  const { isDesktop, isMobile, isDesktopXxl } = useResponsiveness();
  const { loadingState, updateLoadingState } = useLoading();
  const isLoading = loadingState === LoadingState.Loading;

  const [accountDetails, setAccountDetails] = useState({
    profitAndLoss: 0,
    balance: 0,
    equity: 0,
    credits: 0,
    dailyProfitAndLoss: 0,
    allowedDailyLosses: 0,
    snapshotBalance: 0,
    snapshotEquity: 0,
    consecutiveInactivityDays: 0,
  });
  const id = account.tsAccount as string;

  useEffect(() => {
    const getAccountBalance = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getBalance(id);

        setAccountDetails((details) => ({
          ...details,
          profitAndLoss: result.profitAndLoss as number,
          dailyProfitAndLoss: result.dailyProfitAndLoss as number,
          balance: result.balance,
          credits: result.credits as number,
          allowedDailyLosses: result.allowedDailyLosses as number,
          snapshotBalance: result.snapshotBalance as number,
          snapshotEquity: result.snapshotEquity as number,
          equity: result.equity as number,
          consecutiveInactivityDays: result.consecutiveInactivityDays as number,
        }));
      } catch (e) {
        console.error(`Failed getting account details`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    getAccountBalance();
  }, [id]);

  const showDailyMetrics = programStore.hasDailyMetrics(account.programId);
  const showViolations = programStore.hasViolations(account.programId);
  const showProfitableDays = programStore.hasProfitableDays(account.programId);
  const showInactiveDays = programStore.hasInactiveDays(account.programId);
  const isFundedAccount = account.type === AccountType.Funded;
  const showProfitWithdraw =
    isFundedAccount && account.status === AccountStatus.Active;

  const program = programStore.get(account.programId);

  const renderAccountRules = () => (
    <AccountRules
      consecutiveInactivityDays={accountDetails.consecutiveInactivityDays}
      account={account}
    />
  );

  return (
    <Flex gap={24} vertical className={'dashboard-new'}>
      <Row gutter={24}>
        <Col xs={24} xxl={16}>
          <Flex vertical gap={24}>
            {!isDesktop && <AccountInfo account={account} />}
            <div className="daily-card-container">
              <ProfitTarget
                base={program?.config.buyingPower || 0}
                profit={accountDetails.profitAndLoss}
                target={program?.config.target || 0}
                stopOut={program?.config.maxLoss || 0}
              />
              {showViolations && (
                <Violations maxViolations={0} currentViolations={0} />
              )}
              {showDailyMetrics && (
                <DailyLimitLevel
                  accountId={id}
                  application={APPLICATION_TYPE}
                  allowedDailyLosses={accountDetails.allowedDailyLosses || 0}
                  programId={account.programId}
                  equity={accountDetails.equity}
                  baseBalanceWithCredits={
                    (program?.config?.buyingPower || 0) +
                      accountDetails.credits ||
                    program?.config?.buyingPower ||
                    0
                  }
                  midnightBalance={accountDetails.snapshotBalance || 0}
                  midnightEquity={accountDetails.snapshotEquity || 0}
                />
              )}
              {showProfitWithdraw ? (
                <ProfitWithdrawal
                  application={APPLICATION_TYPE}
                  profit={accountDetails.profitAndLoss || 0}
                  account={account}
                />
              ) : showProfitableDays ? (
                <ProfitableDays
                  accountId={account.tsAccount}
                  programId={account.programId}
                  type={DashboardCardType.Progress}
                />
              ) : (
                showInactiveDays && (
                  <InactiveDays
                    isLoading={isLoading}
                    consecutiveInactivityDays={
                      accountDetails.consecutiveInactivityDays
                    }
                    accountId={account.tsAccount}
                    programId={account.programId}
                    type={DashboardCardType.Progress}
                  />
                )
              )}
            </div>

            <Row gutter={[24, 24]}>
              <Col xs={24} lg={16} xxl={24}>
                <Flex vertical gap={24}>
                  <AccountBalance
                    credits={accountDetails.credits}
                    equity={accountDetails.equity}
                    balance={accountDetails.balance}
                    baseBalance={program?.config.buyingPower}
                    profitAndLoss={accountDetails.profitAndLoss}
                    accountId={id}
                    application={APPLICATION_TYPE}
                  />
                  <OpenPositions
                    application={Application.THE5ERS}
                    hideSwap={true}
                    expandTrades={false}
                    accountId={account.tsAccount}
                  />
                  <ClosedPositionsTable
                    application={Application.THE5ERS}
                    expandTrades={false}
                    accountId={account.tsAccount}
                  />
                </Flex>
              </Col>
              {!isDesktopXxl && (
                <Col xs={24} lg={8}>
                  <Card
                    className={classNames('side-stats', {
                      'side-stats--mobile': isMobile,
                      'side-stats--desktop': !isMobile,
                    })}
                  >
                    <Flex vertical gap={32}>
                      {isDesktop && <AccountInfo account={account} />}
                      {renderAccountRules()}
                      <AdvancedStatisticsFivers accountId={id} />
                    </Flex>
                  </Card>
                </Col>
              )}
            </Row>
          </Flex>
        </Col>
        {isDesktopXxl && (
          <Col xs={24} lg={8}>
            <Card active className="side-stats">
              <Flex vertical gap={32}>
                <AccountInfo account={account} />
                {renderAccountRules()}
                <AdvancedStatisticsFivers accountId={id} />
              </Flex>
            </Card>
          </Col>
        )}
      </Row>
    </Flex>
  );
};

export default FiversDashboardNew;
