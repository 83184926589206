import React, { FunctionComponent, lazy, Suspense } from 'react';
import classNames from 'classnames';
import { AccountType, Application } from '@monorepo/types';

import './account-item-icon.scss';

const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

const CompetitionIcon5ers = lazy(
  () => import('./competition-5ers.icon.svg?react')
);
const CompetitionIconTtp = lazy(
  () => import('./competition-ttp.icon.svg?react')
);
const DemoIcon5ers = lazy(() => import('./demo-5ers.icon.svg?react'));
const DemoIconTtp = lazy(() => import('./demo-ttp.icon.svg?react'));
const EvaluationAndFundedIcon5ers = lazy(
  () => import('./funded-5ers.icon.svg?react')
);
const EvaluationAndFundedIconTtp = lazy(
  () => import('./funded-ttp.icon.svg?react')
);

const iconsMap = {
  [Application.THE5ERS]: {
    [AccountType.Funded]: EvaluationAndFundedIcon5ers,
    [AccountType.Evaluation]: EvaluationAndFundedIcon5ers,
    [AccountType.Demo]: DemoIcon5ers,
    [AccountType.Competition]: CompetitionIcon5ers,
  },
  [Application.TTP]: {
    [AccountType.Funded]: EvaluationAndFundedIconTtp,
    [AccountType.Evaluation]: EvaluationAndFundedIconTtp,
    [AccountType.Demo]: DemoIconTtp,
    [AccountType.Competition]: CompetitionIconTtp,
  },
};

interface Props {
  disabled?: boolean;
  accountType: AccountType;
  style?: React.CSSProperties;
  className?: string;
}

const AccountItemIcon: FunctionComponent<Props> = ({
  disabled,
  accountType,
  style,
  className,
}) => {
  const Icon = iconsMap[project][accountType];

  return (
    <Suspense>
      <div
        className={classNames('account-item__icon', className)}
        style={style}
      >
        <div className={classNames('account-item__icon__svg', { disabled })}>
          <Icon />
        </div>
      </div>
    </Suspense>
  );
};

export default AccountItemIcon;
