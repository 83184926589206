import { TextComponent } from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import { SidebarItem, PagePath, Pages } from '@monorepo/types';

type SidebarNavItems = {
  [page in Pages]: {
    key: string;
    icon: IconsNames;
  };
};

const sidebarNavItemsMap: SidebarNavItems = {
  [Pages.Overview]: {
    key: PagePath.Overview,
    icon: IconsNames.Burn,
  },
  [Pages.Orders]: {
    key: PagePath.Orders,
    icon: IconsNames.CoinBag,
  },
  [Pages.Profile]: {
    key: PagePath.Profile,
    icon: IconsNames.Person,
  },
  [Pages.Affiliation]: {
    key: PagePath.Affiliation,
    icon: IconsNames.Compass,
  },
  [Pages.Achievements]: {
    key: PagePath.Achievements,
    icon: IconsNames.Badge,
  },
  [Pages.Autochartist]: {
    key: PagePath.Autochartist,
    icon: IconsNames.ChartAverage,
  },
  [Pages.AssetsSpecifications]: {
    key: PagePath.AssetsSpecifications,
    icon: IconsNames.Event,
  },
  [Pages.HubDownloads]: {
    key: PagePath.HubDownloads,
    icon: IconsNames.BiDownload,
  },
  [Pages.HubVod]: {
    key: PagePath.HubVod,
    icon: IconsNames.Play,
  },
  [Pages.TradingPlan]: {
    key: PagePath.TradingPlan,
    icon: IconsNames.Play,
  },
  [Pages.AffiliateKit]: {
    key: PagePath.AffiliateKit,
    icon: IconsNames.Union,
  },
};

const filterAffiliationItem = (sidebar: SidebarItem[]) =>
  sidebar.filter(({ page }) => page !== Pages.Affiliation);

export const createNavBarItems = ({
  sidebar,
  affiliationItemEnabled,
}: {
  sidebar: SidebarItem[];
  affiliationItemEnabled: boolean;
}) => {
  if (!affiliationItemEnabled) {
    sidebar = filterAffiliationItem(sidebar);
  }

  return sidebar.map(({ label, page }) => {
    const { icon, key } = sidebarNavItemsMap[page];
    return {
      key,
      icon: <Icons iconName={icon} className="navigation-menu__item-icon" />,
      label: (
        <TextComponent className="navigation-menu__item-label">
          {label}
        </TextComponent>
      ),
    };
  });
};
