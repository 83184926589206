import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { EntityStore } from '../base/entity-store';
import { Account, ConsistencyRules } from '@monorepo/types';
import AccountService from '../../../services/entities/account-service';

export class AccountStore extends EntityStore<AccountService, Account> {
  constructor() {
    const {
      apiFactory: { accountService },
    } = getEnv();

    super(accountService);
  }

  getByOrderUniqId(orderUniqId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getByOrderUniqId(orderUniqId);
  }

  getBalanceChart(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getBalanceChart(accountId);
  }

  getBalance(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getBalance(accountId);
  }

  getMidnightHistory(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getMidnightHistory(accountId);
  }

  sync(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.sync(accountId);
  }

  getConsistencyRules(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getConsistencyRules(accountId);
  }

  getConsistencyRule(accountId: string, rule: ConsistencyRules) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getConsistencyRule(accountId, rule);
  }

  getStats(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getStats(accountId);
  }

  getDailyPerformance(accountId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getDailyPerformance(accountId);
  }

  getByTradingSystemAccount(tsAccount: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.getByTradingSystemAccount(tsAccount);
  }

  selectPartner(orderUniqId: string, partnerId: string) {
    const {
      apiFactory: { accountService },
    } = getEnv();

    return accountService.selectPartner(orderUniqId, partnerId);
  }
}
