import React, { FunctionComponent, useState } from 'react';
import { Modal, Space, Tooltip, Typography } from 'antd';
import {
  getPayoutCalculation,
  PaymentMethod,
  PayoutMinimum,
  PayoutSource,
  WithdrawBlockReason,
  WithdrawRulesType,
} from '@monorepo/types';
import { useForm, UseFormReturn } from 'react-hook-form';
import { LoadingState, useLoading } from '@monorepo/client-common';
import { useStore } from '../../helpers/use-store';
import {
  Button,
  FormCheckbox,
  FormInputNumber,
  FormRadioGroup,
} from '@monorepo/react-components';
import { WithdrawPayload } from '../../stores/data/payout/payout-store';
import WiseWithdraw from './withdraw-components/wise-withdraw';
import RiseWithdraw from './withdraw-components/rise-withdraw';
import CryptoWithdraw from './withdraw-components/crypto-withdraw';
import { Icons, IconsNames } from '@monorepo/icons';

import './withdraw-funds.scss';

interface Props {
  maxWithdrawal: number;
  minWithdrawal: number;
  credits: number;
  userPayoutPercentage: number;
  reason?: WithdrawBlockReason;
  showAmountSelection?: boolean;
  disabled?: boolean;
  sourceId: string;
  displayId: string;
  source: PayoutSource;
  onClose: () => Promise<void>;
}

const formatter = (value: any) =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const parser = (value?: string) => value!.replace(/\$\s?|(,*)/g, '');

const createTooltip = (reason: WithdrawBlockReason) => {
  const RuleToText = {
    [WithdrawRulesType.MinimumAmount]: `The minimum for withdraw request is ${
      reason?.ruleValue
    }, your amount is: ${reason?.currentValue?.toFixed(2)}`,
    [WithdrawRulesType.MinimumPayoutCoolDown]: reason?.currentValue
      ? `You have a payment request in the last ${reason?.currentValue} days, you have to wait at least ${reason?.ruleValue} days before submitting a new request.`
      : 'You submitted a payment request today',
    [WithdrawRulesType.MinimumBalance]: `Your current balance available is ${reason?.currentValue?.toFixed(
      2
    )}, the minimum balance allowed for withdraw is ${reason?.ruleValue}`,
    [WithdrawRulesType.MinimumVesting]: `Your have to wait at least ${reason?.ruleValue} days after opening an account before submitting payout request. It has been ${reason?.currentValue} since you opened your account`,
    [WithdrawRulesType.OpenPositions]: `You have to close all positions before submitting a payout request`,
  };
  return RuleToText[reason?.rule];
};

const notes = (source: PayoutSource) => {
  const SourceToNotes = {
    [PayoutSource.Account]: (
      <div>
        • Minimum amount (after split): ${PayoutMinimum}
        <br />
        • Please allow a maximum of 7 days to receive the payout
        <br />
        • Commission to receive in Cryptos: 2%
        <br />• Commission to receive on Rise: 2%
        <br />• Commission to receive on Prepaid credit card: 1.5%
      </div>
    ),
    [PayoutSource.Affiliate]: (
      <div>
        • Minimum amount: ${PayoutMinimum}
        <br />• Please allow a maximum of 7 days to receive the payout
        <br />• 2% clearing fee will be applied to each withdrawal.
      </div>
    ),
  };
  return SourceToNotes[source];
};
const terms = (source: PayoutSource, form: UseFormReturn<WithdrawPayload>) => {
  const SourceToNotes = {
    [PayoutSource.Account]: (
      <>
        <FormCheckbox
          checkboxText="Submitting a payout request can not be reversed"
          form={form}
          controllerProps={{
            name: 'agreedToTerms',
            rules: { required: 'This field is required' },
          }}
        />
        <FormCheckbox
          checkboxText="Request payout will be withdrawn from your trading account"
          form={form}
          controllerProps={{
            name: 'acknowledgeWithdrawFormAccount',
            rules: {
              required: 'Acknowledgement in withdraw terms is required',
            },
          }}
        />
      </>
    ),
    [PayoutSource.Affiliate]: (
      <>
        <FormCheckbox
          checkboxText="Submitting a payout request can not be reversed"
          form={form}
          controllerProps={{
            name: 'agreedToTerms',
            rules: { required: 'This field is required' },
          }}
        />
      </>
    ),
  };
  return SourceToNotes[source];
};

function calculatePayout(
  credits: number,
  selectedAmount: number,
  maxWithdrawal: number,
  userPayoutPercentage: number,
  source: PayoutSource
) {
  const { creditsToDeduct, userShare } = getPayoutCalculation({
    credits,
    amount: selectedAmount == null ? maxWithdrawal : selectedAmount,
    userPayoutPercentage,
    source: source,
  });

  const whatUserGets = creditsToDeduct + userShare;
  const minimumBalanceLegit = whatUserGets >= PayoutMinimum;
  return { whatUserGets, minimumBalanceLegit };
}

const WithdrawFundsContent: FunctionComponent<Props> = ({
  disabled,
  reason,
  displayId,
  onClose,
  source,
  showAmountSelection,
  maxWithdrawal,
  minWithdrawal,
  sourceId,
  userPayoutPercentage,
  credits,
}) => {
  const form = useForm<WithdrawPayload>({
    mode: 'all',
    defaultValues: { amount: maxWithdrawal },
  });
  const [showWithdraw, setShowWithdraw] = useState(false);
  const { loadingState, updateLoadingState } = useLoading();

  const {
    dataStore: { payoutStore },
  } = useStore();

  const isAccountPayout = source === PayoutSource.Account;
  const title = `Payout for ${source} ${
    isAccountPayout ? '#' : ''
  }${displayId}`;

  const reasonTooltip = reason ? createTooltip(reason) : '';

  const handleOk = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      await payoutStore.createPayoutRequest({
        ...form.getValues(),
        amount: showAmountSelection ? form.getValues().amount : maxWithdrawal,
        source: source,
        sourceId: sourceId,
      });

      await onClose();

      updateLoadingState(LoadingState.Loaded);
      setShowWithdraw(false);
    } catch (e) {
      updateLoadingState(LoadingState.Error);
      console.error('could not create payout', e);
    } finally {
      handleCancel();
    }
  };

  const handleCancel = () => {
    form.reset();
    setShowWithdraw(false);
  };

  const selectedPaymentOption = form.watch('paymentOption');
  const selectedAmount = form.watch('amount');

  const getPaymentForm = () => {
    if (!selectedPaymentOption) {
      return null;
    }

    const formsMapping = {
      [PaymentMethod.Wise]: <WiseWithdraw form={form} />,
      [PaymentMethod.Rise]: <RiseWithdraw form={form} />,
      [PaymentMethod.Crypto]: <CryptoWithdraw form={form} />,
      [PaymentMethod.CreditCard]: null,
      [PaymentMethod.HubCredits]: null,
    };

    return formsMapping[selectedPaymentOption];
  };

  const { whatUserGets } = calculatePayout(
    credits,
    selectedAmount,
    maxWithdrawal,
    userPayoutPercentage,
    source
  );

  return (
    <>
      <Modal
        open={showWithdraw}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !form.formState.isValid,
          loading: loadingState === LoadingState.Loading,
        }}
        okText={'Submit'}
      >
        <Typography.Title level={2}>{title}</Typography.Title>
        <Typography.Text type="secondary">
          Available withdrawal for your account: ${maxWithdrawal.toFixed(2)}
        </Typography.Text>
        {showAmountSelection && (
          <>
            <FormInputNumber
              form={form}
              props={{
                formatter: formatter,
                parser: parser,
              }}
              controllerProps={{
                name: 'amount',
                rules: {
                  max: {
                    value: maxWithdrawal,
                    message: `Maximum to withdraw is ${maxWithdrawal}`,
                  },
                  validate: {
                    minimum: (value) => {
                      const { minimumBalanceLegit } = calculatePayout(
                        credits,
                        value,
                        maxWithdrawal,
                        userPayoutPercentage,
                        source
                      );
                      if (!minimumBalanceLegit) {
                        return `Minimum amount after split is ${PayoutMinimum}`;
                      }
                      return true;
                    },
                  },
                },
              }}
            />
            <Typography.Text type="secondary">
              Your profit share after split is: {whatUserGets}
            </Typography.Text>
          </>
        )}
        <Typography.Title level={5}>Payment methods:</Typography.Title>
        <Space direction="vertical" size="large">
          <FormRadioGroup
            direction={'vertical'}
            form={form}
            options={[
              {
                value: PaymentMethod.Rise,
                text: 'Rise',
              },
              {
                value: PaymentMethod.HubCredits,
                text: 'Hub credits',
              },
              {
                value: PaymentMethod.Crypto,
                text: 'Crypto',
              },
            ]}
            controllerProps={{
              name: 'paymentOption',
              rules: { required: true },
            }}
          />

          {getPaymentForm()}

          <Space direction="vertical" size="small" className="terms">
            {terms(source, form)}
          </Space>
          {notes(source)}
        </Space>
      </Modal>
      <Space direction={'horizontal'}>
        <Tooltip title={reasonTooltip}>
          <Button
            shape={'round'}
            className={'withdraw-button'}
            icon={<Icons iconName={IconsNames.Cash} />}
            disabled={disabled}
            onClick={() => setShowWithdraw(true)}
          >
            Withdraw
          </Button>
        </Tooltip>
      </Space>
    </>
  );
};

export default WithdrawFundsContent;
