import { FunctionComponent, PropsWithChildren } from 'react';
import PageContainer from '../page-container/page-container';
import { Flex } from '@monorepo/react-components';
import {
  Account,
  AccountStatus,
  AccountType,
  DashboardLayouts,
  PayoutSource,
  Program,
} from '@monorepo/types';
import DashboardAccountStatusTag from './dashboard-common/dashboard-account-status-tag/dashboard-account-status-tag';
import { abbreviatePrice, useResponsiveness } from '@monorepo/client-common';
import ChoosePartnerButton from '../choose-partners-button/choose-partner-button';
import AccountCredentialsButton from '../account-credentials-button/account-credentials-button';
import WithdrawFunds from '../withdraw-funds/withdraw-funds';

interface Props extends PropsWithChildren {
  account: Account;
  program?: Program;
  dashboard?: DashboardLayouts;
}

const DashboardWrapper: FunctionComponent<Props> = ({
  account,
  children,
  program,
  dashboard,
}) => {
  const { isDesktop } = useResponsiveness();

  const id = account.tsAccountId;
  const shouldShowPartnersButton =
    isDesktop && account && dashboard === DashboardLayouts.TTPDashboard;

  const pageTitle = `#${id} - ${program?.name} - ${abbreviatePrice(
    program?.config.buyingPower
  )}`;

  const isAllowedToWithdraw =
    account.type === AccountType.Funded &&
    account.status === AccountStatus.Active;

  return (
    <PageContainer
      titleBar={
        <Flex align="center" gap={16}>
          {isAllowedToWithdraw && (
            <WithdrawFunds
              sourceId={account._id}
              source={PayoutSource.Account}
              displayId={account.tsAccount}
            />
          )}
          {shouldShowPartnersButton && (
            <ChoosePartnerButton account={account} />
          )}
          <AccountCredentialsButton account={account} />
        </Flex>
      }
      titlePostfix={
        <DashboardAccountStatusTag
          accountStatus={account.status}
          accountState={account.state}
        />
      }
      helmet={`Account-${id}`}
      pageTitle={pageTitle}
    >
      {children}
    </PageContainer>
  );
};
export default DashboardWrapper;
