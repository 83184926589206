import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { getEnv, getRoot } from '../../../helpers/mobx-easy-wrapper';
import {
  Application,
  Contract,
  ContractData,
  ContractStatus,
  ContractType,
  isUserBlocked,
  LoginResponse,
  NotificationData,
  NotificationName,
  TradingSystem,
  User,
  UserCoupon,
  UserCouponStatus,
  UserPayload,
} from '@monorepo/types';
import { RealTimeData } from '../real-time/real-time-store';
import dayjs from 'dayjs';
import {
  AppCookieType,
  deleteAppCookie,
  deleteLeadDynoData,
  getAppCookie,
} from '@monorepo/client-common';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';

const prefix = reactEnv === 'production' ? '' : `${reactEnv.slice(0, 3)}-`;

export const KYC_TOKEN_KEY = `${prefix}kyc-token`;
export const TOKEN_KEY = `${prefix}ttp`;
export const REFRESH_TOKEN_KEY = `${prefix}refresh-ttp`;
export const USER_ID = `${prefix}ttp-user-id`;

type UserNotificationData = NotificationData & RealTimeData;
const project: Application = import.meta.env.VITE_PROJECT || Application.TTP;

export default class UserStore {
  @observable
  isLoggedIn: boolean = false;

  @observable
  contracts: Contract[] = [];

  @observable
  coupons: UserCoupon[] = [];

  @observable
  notificationsMap: Map<string, UserNotificationData> = new Map<
    string,
    UserNotificationData
  >();

  @observable
  unreadNotifications = 0;

  @observable
  currentUser: User = {} as User;

  @observable
  affiliationDashboard = '';

  @observable
  affiliationCommissionsAvailable = 0;

  @observable
  isImpersonated: boolean = false;

  constructor() {
    makeObservable(this);
  }

  async updateCredits() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      const updatedCredits = await userService.getCredits();

      runInAction(() => {
        this.currentUser.creditBalance = updatedCredits;
      });
    } catch (e) {
      console.error(`Failed updating credits`, e);
    }
  }

  async getCreditsHistory() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      return await userService.getCreditsHistory();
    } catch (e) {
      console.error(`Failed getting credits history`, e);
      throw e;
    }
  }

  async updateLogins() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      const logins = await userService.getLogins();

      runInAction(() => {
        this.currentUser.tsUsers = logins;
      });

      return logins;
    } catch (e) {
      console.error(`Failed updating credits`, e);
    }
  }

  async register(userData: UserPayload) {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      await userService.registerUser(userData);
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }

  async confirm(confirmationCode: string): Promise<boolean> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      return await userService.confirm(confirmationCode);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getUserContracts(): Promise<void> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      const contracts = await userService.getUserContracts();
      runInAction(() => {
        this.contracts = contracts;
      });
    } catch (e) {
      console.error(e);
    }
  }

  async getUserCoupons(): Promise<void> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const coupons = await userService.getUserCoupons();
      runInAction(() => {
        this.coupons = coupons;
      });
    } catch (e) {
      console.error(e);
    }
  }

  async createUserCoupon(params: {
    org: string;
    name: string;
  }): Promise<{ coupon: UserCoupon | null; status: UserCouponStatus }> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      const coupon = await userService.createUserCoupon(params);

      runInAction(() => {
        if (
          coupon &&
          coupon?.status === UserCouponStatus.Valid &&
          coupon.coupon
        ) {
          this.coupons = [...this.coupons, coupon.coupon];
        }
      });

      return coupon;
    } catch (e) {
      console.error(e);
      return { coupon: null, status: UserCouponStatus.Other };
    }
  }

  async resetPassword(
    resetPasswordCode: string,
    newPassword: string
  ): Promise<boolean> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      return await userService.resetPassword(resetPasswordCode, newPassword);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async forgetPassword(email: string): Promise<boolean> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      return await userService.forgetPassword(email);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  logout() {
    this.setCurrentUser({} as User);
    this.setLoginState(false);
    const { apiFactory } = getEnv();
    const {
      dataStore: { realTimeStore },
      uiStore: { getLogoutUrl },
    } = getRoot();

    realTimeStore.disconnect();
    apiFactory.removeToken();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem('initialUrl');

    window.location.href = getLogoutUrl;
  }

  async login(
    email?: string,
    password?: string
  ): Promise<LoginResponse | undefined> {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const user = await userService.login(email, password);

      const { token, refreshToken, ...rest } = user as LoginResponse;

      if (token && refreshToken) {
        this.saveToken(token, refreshToken);
      }

      this.setCurrentUser(rest);
      this.setLoginState(true);
      localStorage.setItem(USER_ID, rest.userId);
      await this.getUserUnreadNotifications();
      this.recordLead({
        email: this.currentUser.email,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
      });
      return user;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createKycToken() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const kycToken = await userService.getKycAccessToken();
      localStorage.setItem(KYC_TOKEN_KEY, kycToken);
      return kycToken;
    } catch (e) {
      console.log('Error occurred on creation of token');
    }
  }

  getKycToken() {
    return localStorage.getItem(KYC_TOKEN_KEY);
  }

  saveToken(token: string, refreshToken: string, adminToken: string = '') {
    const { apiFactory } = getEnv();
    apiFactory.saveToken(token, this.refreshToken, adminToken);
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  refreshToken = async () => {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      if (this.isImpersonated) {
        throw new Error('Refresh token cannot be performed on impersonation');
      }

      const userId = localStorage.getItem(USER_ID);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (!refreshToken || !userId) {
        return '';
      }

      const newToken = await userService.refreshToken(refreshToken, userId);
      localStorage.setItem(TOKEN_KEY, newToken);
      return newToken;
    } catch (e) {
      console.error(`failed refreshing token`, e);

      this.logout();

      return '';
    }
  };

  async update(userDataToUpdate: Partial<User>) {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const updatedUser = await userService.updateUser(userDataToUpdate);
      this.setCurrentUser({ ...this.currentUser, ...updatedUser });
      return updatedUser;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }

  async getUserNotifications() {
    const {
      apiFactory: { userService },
    } = getEnv();
    try {
      this.markAllAsRead();
      const notifications = await userService.getNotifications();
      this.updateNotifications(notifications);
    } catch (e) {
      console.error(e);
    }
  }

  async getUserUnreadNotifications() {
    const {
      apiFactory: { userService },
    } = getEnv();
    try {
      const unread = await userService.getUnreadNotifications();
      this.updateUnreadNotifications(unread);
    } catch (e) {
      console.error(e);
    }
  }

  async uploadProfileImage(file: FormData) {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const imageUrl = await userService.uploadProfilePicture(file);
      runInAction(() => {
        this.currentUser.profileUrl = imageUrl;
      });
      return imageUrl;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteProfileImage() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const response = await userService.deleteProfilePicture();
      runInAction(() => {
        this.currentUser.profileUrl = undefined;
      });
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async registerAsAffiliate() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      const affiliateId = await userService.registerAsAffiliate();
      runInAction(() => {
        this.setCurrentUser({
          ...this.currentUser,
          affiliateId,
        });
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getUserVerificationData() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      const userVerificationData = await userService.getUserVerificationData();

      runInAction(() => {
        this.setCurrentUser({
          ...this.currentUser,
          verificationData: userVerificationData,
        });
      });
    } catch (e) {
      console.error(`Failed updating user`, e);
    }
  }

  async getUserAffiliationDashboard() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      const affiliateDashboardUrl =
        await userService.getUserAffiliationDashboard();
      runInAction(() => {
        this.affiliationDashboard = affiliateDashboardUrl;
      });
    } catch (e) {
      console.error(`Failed updating user`, e);
    }
  }

  contractSignNeeded(contractType: ContractType, version: number) {
    try {
      const userCurrentContract = this.contracts.find(
        (contract) =>
          contractType === contract.type && version === contract.version
      );

      return (
        !userCurrentContract ||
        userCurrentContract.status !== ContractStatus.Signed
      );
    } catch (e) {
      console.error(
        `failed checking if user need to sign contract with error:`,
        e
      );
    }
  }

  async signUserContract(
    contractType: ContractType,
    contractData?: ContractData
  ) {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();
      await userService.signUserContract({ contractType, contractData });
      await this.getUserContracts();
    } catch (e) {
      console.error(
        `failed checking if user need to sign contract with error:`,
        e
      );
    }
  }

  async recordLead(params: Omit<UserPayload, 'password' | 'country'>) {
    try {
      const {
        apiFactory: { userService },
        envConfig: { domain_name: domain },
      } = getEnv();
      const oldAffiliateCode = getAppCookie(
        AppCookieType.OldAffiliation,
        project
      );
      let isOldAffiliationRecorded = false;

      if (oldAffiliateCode) {
        isOldAffiliationRecorded = await userService.recordLeadByOldAffiliation(
          {
            ...params,
            oldAffiliationCode: oldAffiliateCode,
            url: window.location.href,
          }
        );

        if (isOldAffiliationRecorded) {
          window.LeadDyno.devTools.reset();
          deleteLeadDynoData(domain);
        }
        deleteAppCookie(AppCookieType.OldAffiliation, domain, project);
      }

      if (!oldAffiliateCode || !isOldAffiliationRecorded) {
        window.LeadDyno.recordLead(params.email, {
          first_name: params.firstName,
          last_name: params.lastName,
        });
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @action
  setCurrentUser(data: User) {
    this.currentUser = data;
  }

  @action
  setLoginState(loginState: boolean) {
    this.isLoggedIn = loginState;
  }

  @action
  toggleImpersonation() {
    this.isImpersonated = true;
  }

  @computed
  get fullName() {
    return `${this.currentUser.firstName.trim()} ${this.currentUser.lastName.trim()}`;
  }

  @computed
  get notifications() {
    return Array.from(this.notificationsMap.values()).sort((a, b) =>
      dayjs(b.createdAt).diff(dayjs(a.createdAt))
    );
  }

  @action
  handleUserNotification(notificationData: UserNotificationData) {
    if (!this.notificationsMap.has(notificationData.id)) {
      this.unreadNotifications++;
      this.notificationsMap.set(notificationData.id, notificationData);
    }
  }

  @action
  updateNotifications(notificationData: NotificationData[]) {
    this.notificationsMap.clear();
    notificationData.forEach((notification) => {
      if (notification.message !== '') {
        this.notificationsMap.set(notification.id, notification);
      }
    });
  }

  @action
  updateUnreadNotifications(unreadNotifications: number) {
    this.unreadNotifications = unreadNotifications;
  }

  @computed
  get verificationRequired() {
    return (
      this.currentUser.verificationData &&
      isUserBlocked(this.currentUser.verificationData)
    );
  }

  @action
  markAllAsRead() {
    this.unreadNotifications = 0;
  }

  registerToRealTimeData() {
    const {
      dataStore: { realTimeStore },
    } = getRoot();

    realTimeStore.registerToEvent<NotificationData>(
      NotificationName.UserVerificationRequired,
      async (data) => {
        await this.getUserVerificationData();
        this.handleUserNotification(data);
      }
    );

    realTimeStore.registerToEvent<NotificationData>(
      NotificationName.NewAccount,
      async (data) => {
        this.handleUserNotification(data);
      }
    );

    realTimeStore.registerToEvent<NotificationData>(
      NotificationName.AddOnApplied,
      async (data) => {
        this.handleUserNotification(data);
      }
    );

    realTimeStore.registerToEvent<NotificationData>(
      NotificationName.UserVerificationCompleted,
      async (data) => {
        await this.getUserVerificationData();
        this.handleUserNotification(data);
      }
    );
  }

  async decryptPassword(login: string, tradingSystem: TradingSystem) {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      return await userService.decryptPassword(login, tradingSystem);
    } catch (e) {
      console.error(`Failed decrypting password`, e);
    }
  }

  async getAffiliateAvailableSum() {
    try {
      const {
        apiFactory: { userService },
      } = getEnv();

      return await userService.getAffiliateAvailableCommissions();
    } catch (e) {
      console.error(`Failed getting affiliate available sum`, e);
      return 0;
    }
  }
}
