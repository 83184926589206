import { ThemeConfigurationType } from '@monorepo/types';
import {
  commonComponentTheme,
  commonCssVars,
  commonLightCssEnv,
} from './common.theme-settings';
import { theme } from 'antd';

export const LightThemeConfigTtp: ThemeConfigurationType = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: '#6710FF',
    colorSuccess: '#179DA6',
    colorWarning: '#E9C10F',
    colorError: '#EB0065',
    colorInfo: '#256BE4',
    colorLink: '#3240C2',
    colorBgBase: '#fff',
    colorTextSecondary: '#000000',
    colorText: '#000000',
    colorBgLayout: '#fafafa',
  },
  components: commonComponentTheme,
  cssVars: {
    ...commonCssVars,
    ...commonLightCssEnv,

    colorPrimary: '#6710FF',
    colorSuccess: '#179DA6',
    colorWarning: '#E9C10F',
    colorError: '#EB0065',
    colorInfo: '#256BE4',
    colorLink: '#3240C2',
    colorTextSecondary: '#000000',
    colorText: '#000000',
    colorBgLayout: '#fafafa',
    colorAttention: '#FF4D9E',
    colorActive: '#EB0064',
    colorReverse: '#ffffff',
    colorErrorLight: 'rgb(255, 208, 228)',
    colorSecondary: '#FF4D9E',
  },
};
