import {
  ACCOUNT_STATE_TITLES,
  AccountState,
  AccountStateKey,
  AccountStatus,
  ThemeColors,
} from '@monorepo/types';
import { Label, TextComponent, Tooltip } from '@monorepo/react-components';
import { FunctionComponent } from 'react';
import { Icons, IconsNames } from '@monorepo/icons';

import './dashboard-account-status-tag.scss';

type TagSetting = {
  color?: ThemeColors;
  tooltip?: string;
};

const getTagSettings = (
  status: AccountStatus,
  state: AccountState
): TagSetting => {
  if (status === AccountStatus.Disabled) {
    const terminationSettings: Record<AccountStateKey, TagSetting> = {
      [AccountStateKey.DailyPause]: {
        color: ThemeColors.colorInfo,
        tooltip: ACCOUNT_STATE_TITLES[AccountStateKey.DailyPause],
      },
      [AccountStateKey.DailyLoss]: {
        color: ThemeColors.colorInfo,
        tooltip: ACCOUNT_STATE_TITLES[AccountStateKey.DailyLoss],
      },
      [AccountStateKey.Terminated]: {
        tooltip: ACCOUNT_STATE_TITLES[AccountStateKey.Terminated],
      },
      [AccountStateKey.Expired]: {
        color: ThemeColors.colorError,
        tooltip: ACCOUNT_STATE_TITLES[AccountStateKey.Expired],
      },
      [AccountStateKey.Target]: {
        color: ThemeColors.colorWarning,
        tooltip: ACCOUNT_STATE_TITLES[AccountStateKey.Target],
      },
      [AccountStateKey.Swing]: {
        color: ThemeColors.colorWarning,
        tooltip: ACCOUNT_STATE_TITLES[AccountStateKey.Swing],
      },
    };

    const userTerminations: Array<TagSetting> = Object.entries(state)
      .filter(([_, value]) => value)
      .map(
        ([terminationState, _]) =>
          terminationSettings[terminationState as AccountStateKey]
      );

    if (userTerminations.length > 0) {
      return userTerminations[0];
    }
  }

  const tagColors: Record<AccountStatus, ThemeColors> = {
    [AccountStatus.Active]: ThemeColors.colorSuccess,
    [AccountStatus.Disabled]: ThemeColors.colorError,
    [AccountStatus.PendingCreation]: ThemeColors.colorInfo,
  };

  return { color: tagColors[status] };
};

interface Props {
  accountStatus: AccountStatus;
  accountState: AccountState;
}

const DashboardAccountStatusTag: FunctionComponent<Props> = ({
  accountStatus,
  accountState,
}) => {
  const { tooltip, color } = getTagSettings(accountStatus, accountState);

  return (
    <Tooltip title={tooltip}>
      <Label
        className="dashboard-account-status-tag"
        onClick={(event) => event.stopPropagation()}
        color={color}
        icon={tooltip ? <Icons iconName={IconsNames.QuestionCircle} /> : null}
      >
        <TextComponent capitalize level={9}>
          {accountStatus}
        </TextComponent>
      </Label>
    </Tooltip>
  );
};
export default DashboardAccountStatusTag;
