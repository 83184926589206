import { App, Modal } from 'antd';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  CouponItemType,
  isUserCouponRedeemAble,
  ProductType,
  UserCoupon,
  UserCouponStatus,
} from '@monorepo/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import {
  Statistic,
  TextComponent,
  Button,
  Divider,
  Flex,
  Label,
} from '@monorepo/react-components';
import dayjs from 'dayjs';
import { useThemeToken } from '@monorepo/client-common';
import { Icons, IconsNames } from '@monorepo/icons';

import './coupon-modal.scss';

const CouponModal: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState<UserCouponStatus | null>(null);
  const [createdCoupon, setCreatedCoupon] = useState<UserCoupon | null>(null);
  const { org, name } = useParams<{ org: string; name: string }>();
  const { message } = App.useApp();
  const { token } = useThemeToken();
  const navigate = useNavigate();
  const {
    dataStore: { userStore },
  } = useStore();

  useEffect(() => {
    const init = async () => {
      if (!org || !name) {
        return;
      }
      const { status, coupon } = await userStore.createUserCoupon({
        org,
        name,
      });
      if (status === UserCouponStatus.Other) {
        message.error('Sorry, something went wrong.');
      }
      if (
        status === UserCouponStatus.Expired ||
        status === UserCouponStatus.UsedOut
      ) {
        setErrorStatus(status);
        setCreatedCoupon(null);
        setOpen(true);
      }
      if (status === UserCouponStatus.Valid && coupon) {
        setCreatedCoupon(coupon);
        setErrorStatus(null);
        setOpen(true);
      }
    };
    init();
  }, []);

  const isCreatedCouponModal = !errorStatus && createdCoupon;
  const isErrorModal = errorStatus && !createdCoupon;
  const renderErrorModal = () => {
    if (!errorStatus) {
      return;
    }
    const subtitleMap: { [key: string]: string } = {
      [UserCouponStatus.UsedOut]:
        'Those coupons went faster than a scalped ticket at the World Series.',
      [UserCouponStatus.Expired]: 'This coupon is Already expired',
    };
    const reason = subtitleMap[errorStatus] || 'This coupon is no longer valid';

    return (
      <Flex vertical align={'center'} style={{ textAlign: 'center' }} gap={18}>
        <TextComponent level={3} strong>
          Heads up!
        </TextComponent>
        <TextComponent level={5} strong>
          {reason}
        </TextComponent>
        <TextComponent level={6}>
          But hey, don't sweat it - the market moves fast.
        </TextComponent>
        <Label color="colorError">Expired</Label>
        <TextComponent level={7}>
          In the meantime, stay ahead of the game and explore the site. You
          might just uncover your next golden opportunity.
        </TextComponent>
        <Divider />
        <Button type={'primary'} onClick={() => setOpen(false)}>
          Close
        </Button>
      </Flex>
    );
  };

  const renderCouponModal = () => {
    if (!isCreatedCouponModal) {
      return;
    }
    const couponItem = createdCoupon.items[0];

    const isForFree =
      couponItem.amount === 100 &&
      couponItem.couponType === CouponItemType.Percentage;

    const valueSign =
      couponItem.couponType === CouponItemType.Nominal
        ? {
            prefix: '$',
          }
        : {
            suffix: '%',
          };

    const Amount = () => (
      <Statistic {...valueSign} value={couponItem.amount} precision={0} />
    );

    const headerMap: { [key: string]: string } = {
      [ProductType.Program]: 'Get ready to get funded!',
      [ProductType.General]: 'Level up your buying power!',
      [ProductType.AddOn]: 'Supercharge your trade!',
      [ProductType.Credit]: 'Boost your buying power!',
    };
    const discountDescription = isForFree ? (
      'free'
    ) : (
      <>
        <Amount /> discount
      </>
    );

    const mainTitleMap: { [key: string]: ReactNode } = {
      [ProductType.Program]: `${
        couponItem.metadata?.productName
          ? couponItem.metadata.productName
          : couponItem.productType
      }`,
      [ProductType.General]: <Amount />,
      [ProductType.AddOn]: `${
        couponItem.metadata?.productName
          ? couponItem.metadata.productName
          : couponItem.productType
      }`,
      [ProductType.Credit]: <Amount />,
    };

    const subtitleMap: { [key: string]: ReactNode } = {
      [ProductType.Program]: (
        <>
          for <Amount />
        </>
      ),
      [ProductType.General]: 'discount on your next purchase',
      [ProductType.AddOn]: <>for {discountDescription}</>,
      [ProductType.Credit]: 'in bonus credits',
    };

    const title = headerMap[couponItem.productType];
    const itemMainTitle = mainTitleMap[couponItem.productType];
    const itemSubTitle = subtitleMap[couponItem.productType];

    const handleClick = () => {
      const isRedeemAble = isUserCouponRedeemAble(createdCoupon);
      if (isRedeemAble) {
        navigate(`/new-account/?couponId=${createdCoupon._id}`);
      }
      setOpen(false);
    };

    return (
      <Flex vertical align={'center'} className="coupon-modal" gap={24}>
        <TextComponent level={3} weight={600}>
          {title}
        </TextComponent>
        <Flex vertical align={'center'} gap={8}>
          <TextComponent responsive={false} level={7} weight={600}>
            Unlocked
          </TextComponent>
          <TextComponent level={1} color={token.colorSuccess} weight={700}>
            {itemMainTitle}
          </TextComponent>
          <TextComponent
            className="coupon-modal__subtitle"
            level={6}
            color={token.colorPrimary}
            weight={600}
          >
            {itemSubTitle}
          </TextComponent>
          <Label color="colorError">
            Expires on{' '}
            {dayjs(createdCoupon?.expirationDate).format('DD.MM.YYYY')}
          </Label>
        </Flex>
        <Flex align="start" gap={4}>
          <TextComponent level={8}>
            <Icons iconName={IconsNames.QuestionCircle} />
          </TextComponent>
          <TextComponent level={7} textAlign="left">
            Click "Redeem" to activate your coupon. If you prefer to use it
            later, it will be available on your orders page until it expires.
          </TextComponent>
        </Flex>
        <Divider className="coupon-modal__divider" />
        <Flex align="center" gap={8}>
          <Button type={'text'} onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button type={'primary'} onClick={handleClick}>
            Redeem
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal open={open} closable onCancel={() => setOpen(false)} footer={null}>
      {isCreatedCouponModal && renderCouponModal()}
      {isErrorModal && renderErrorModal()}
    </Modal>
  );
};

export default CouponModal;
