import { FunctionComponent, ReactNode } from 'react';
import { Account } from '@monorepo/types';
import {
  Button,
  Flex,
  Statistic,
  TextComponent,
  Col,
  Row,
} from '@monorepo/react-components';
import dayjs from 'dayjs';
import { useStore } from '../../helpers/use-store';
import DashboardAccountStatusTag from '../dashboard-templates/dashboard-common/dashboard-account-status-tag/dashboard-account-status-tag';
import { useResponsiveness } from '@monorepo/client-common';
import {
  AccountItemAvatar,
  AccountItemCard,
  AccountItemMobile,
} from './account-item.util';

import './account-item.scss';

export type AccountWithBalance = Account & { balance: number };

const AccountItem: FunctionComponent<{
  account: AccountWithBalance;
  showExpiry: boolean;
}> = ({ account, showExpiry }) => {
  const { isMobile } = useResponsiveness();

  const {
    dataStore: { programStore },
  } = useStore();

  const program = programStore.get(account.programId);

  const Content: FunctionComponent<{
    title: string;
    value: string | ReactNode;
  }> = ({ title, value }) => (
    <Flex vertical gap={3}>
      <TextComponent type="secondary" level={8}>
        {title}
      </TextComponent>
      <TextComponent noWrap capitalize level={6}>
        {value}
      </TextComponent>
    </Flex>
  );
  if (isMobile) {
    return <AccountItemMobile account={account} program={program} />;
  }

  if (isMobile) {
    return <AccountItemMobile account={account} program={program} />;
  }

  return (
    <AccountItemCard account={account}>
      <Row gutter={[12, 12]} align="middle">
        <Col xs={12} sm={10} lg={7}>
          <AccountItemAvatar account={account} groupName={program?.groupName} />
        </Col>

        {program && (
          <Col xs={12} sm={8} lg={4}>
            <Content
              title="Size"
              value={
                <Statistic
                  value={program.config.buyingPower}
                  precision={2}
                  prefix={'$'}
                />
              }
            />
          </Col>
        )}
        <Col xs={12} sm={6} lg={4}>
          <Content
            title="Balance"
            value={
              <Statistic value={account.balance} precision={2} prefix={'$'} />
            }
          />
        </Col>
        <Col xs={12} sm={10} lg={3}>
          <Content
            title="Status"
            value={
              <DashboardAccountStatusTag
                accountState={account.state}
                accountStatus={account.status}
              />
            }
          />
        </Col>

        {showExpiry && (
          <Col xs={12} sm={8} lg={3}>
            <Content
              title="Expiration date"
              value={
                account.expireDate
                  ? dayjs(account.expireDate).format('MM.DD.YYYY')
                  : '-'
              }
            />
          </Col>
        )}
        <Col xs={12} sm={6} lg={3}>
          <Button ghost className="button" type="primary">
            Dashboard
          </Button>
        </Col>
      </Row>
    </AccountItemCard>
  );
};

export default AccountItem;
