import { ThemeConfig } from 'antd';

const INPUT_HEIGHT_LG = 48;

export const commonCssVars = {
  'max-content-width': '1463px',
  'padding-y-layout': '22px',
  'padding-y-mobile': '16px',
  'padding-x-layout': 'clamp(24px, 4.5vw, 81px)',
  'padding-y-content': 'clamp(24px, 4vw, 41px)',
  'padding-y-menu': '16px',
  'padding-x-menu': '24px',
  'content-padding': '12px',
  'header-height': '76px',
  'footer-height': '76px',

  'card-padding-y': 'clamp(24px, 5vw, 32px)',
  'card-padding-x': 'clamp(16px, 5vw, 24px)',

  'button-border-radius': '4px',
  'button-height-lg': '52px',
  'button-sm-icon-margin-inline-end': '0px',

  'color-white': '#ffffff',
  'color-bg-mask': 'rgba(0, 0, 0, 0.06)',

  'color-description': 'rgba(0, 0, 0, 0.45)',

  'input-height-lg': `${INPUT_HEIGHT_LG}px`,
  'input-height-lg-prefix': '32px',
  'input-border-radius': '4px',

  'descriptions-table-layout': 'auto',
  'descriptions-row-padding-bottom': '0',
  'descriptions-row-padding-right': '33px',

  'tag-margin-inline-end': '0',

  'statistic-prefix-margin': '0',

  'table-header-color': '#969696',
  'table-header-font-size': '12px',
  'table-header-font-weight': '400',

  'table-header-border-display': 'none',
  'table-header-text-align': 'center',
  'table-body-text-align': 'center',
  'table-body-font-size': '12px',
  'table-body-whitespace': 'nowrap',
  'table-row-hover': 'rgba(253, 28, 124, 0.1)',
  'table-td-padding-left': '8px',
  'table-border-radius': '0',
  'table-header-whitespace': 'nowrap',

  'color-rail-default': '#e1e1e1',
  'color-danger': '#ff4d9e',
  colorDescription: '#4b4b4b',

  'table-header-bg-has-sort': 'rgb(245, 245, 245)',

  'nav-menu-border-color': 'var(--border-color)',
  'nav-menu-bg-hamburger': '#e1e1e1',
  'nav-menu-active-color': '#FFFFF',
  'nav-menu-icon-size': '24px',
  'nav-menu-label-font-size': '14px',

  'text-level-first': '48px',
  'text-level-first-mobile': '32px',
  'text-level-second': '32px',
  'text-level-second-mobile': '28px',
  'text-level-third': '24px',
  'text-level-third-mobile': '16px',
  'text-level-fourth': '20px',
  'text-level-fourth-mobile': '18px',
  'text-level-fifth': '18px',
  'text-level-fifth-mobile': '16px',
  'text-level-sixth': '16px',
  'text-level-sixth-mobile': '14px',
  'text-level-seventh': '14px',
  'text-level-seventh-mobile': '12px',
  'text-level-eighth': '12px',
  'text-level-eighth-mobile': '12px',
  'text-level-ninth': '11px',
  'text-level-tenth': '9px',
  'text-level-tenth-mobile': '8px',
  'evaluation-color': 'var(--colorSecondary)',
  'demo-color': 'var(--colorSuccess)',
  'competition-color': 'var(--colorInfo)',
  'funded-color': 'var(--colorPrimary)',
};

export const commonLightCssEnv = {
  'radio-active-button-shadow': '6px 15px 25px 0px rgba(190, 200, 218, 0.40)',
  'sider-shadow': '6px 15px 25px 0px rgba(207, 212, 220, 0.4)',
  'header-shadow': '6px 15px 25px 0px rgba(207, 212, 220, 0.4)',
  'header-border-color': '#e1e1e1',
  'header-minimal-bg': '#fff',
  'header-avatar-color': '#000000',
  'card-border-color': '#e1e1e1',
  'color-text-seed': '#000000',
  'table-header-bg': '#f6f6f6',
  'border-color': '#e1e1e1',
  'input-border-color': '#afafaf',
  'card-shadow': '0px 4px 5px 1px rgba(193, 193, 193, 0.35)',
  'card-shadow-hover': '6px 15px 25px 0px rgba(190, 200, 218, 0.40)',
  'radio-active-button-font-weight': '600',
  colorReverse: '#FFFFFF',
  colorBgBase: '#fff',
  'color-bg-box': '#FFFFFF',
  'coupon-img-filter':
    'drop-shadow(1.378px 3.446px 5.743px rgba(190, 200, 218, 0.4))',
  'icon-drop-shadow-filter':
    'drop-shadow(1px 5px 4px rgba(193, 193, 193, 0.35))',
  'account-item-bg-disabled': '#F6F6F6',
};

export const commonDarkCssEnv = {
  'radio-active-button-shadow': 'unset',
  'header-shadow': 'unset',
  'header-border-color': '#454C52',
  'header-minimal-bg': 'var(--color-bg-box)',
  'header-avatar-color': '#000000',
  'table-header-bg': '#1A1D21',
  'radio-active-button-font-weight': '600',
  'sider-shadow': '6px 15px 25px 0px rgba(0, 0, 0, 0.40)',
  'card-active-border-color': '#6710FF',
  'card-border-color': '#454C52',
  'card-shadow': '6px 15px 25px 0px rgba(0, 0, 0, 0.40)',
  'card-shadow-hover': 'unset',
  'color-text-seed': '#DDDDDD',
  'input-border-color': '#454C52',
  'border-color': '#454C52',
  colorReverse: '#000000',
  colorBgBase: '#475057',
  'color-bg-box': '#292E32',
  'coupon-img-filter': 'unset',
  'icon-drop-shadow-filter': 'drop-shadow(1px 5px 4px rgba(28, 28, 28, 0.65))',
  'account-item-bg-disabled': '#1A1D21',
};

export const commonComponentTheme: ThemeConfig['components'] = {
  Typography: {
    fontFamily: 'Mona Sans',
    colorText: 'var(--color-text-seed)',
  },
  Layout: {
    headerBg: 'transparent',
  },
  Menu: {
    iconMarginInlineEnd: 16,
    itemBorderRadius: 0,
    itemHeight: 40,
    itemMarginInline: 0,
    itemSelectedColor: '#FFFFFF',
    colorBgMask: 'transparent',
    colorBorder: 'transparent',
    colorBorderBg: 'transparent',
    itemActiveBg: 'var(--colorAttention)',
    itemSelectedBg: 'var(--colorAttention)',
    activeBarBorderWidth: 0,
  },
  Drawer: {
    colorBgMask: 'rgba(0, 0, 0, 0.06)',
    paddingXL: 0,
    paddingLG: 0,
    paddingMD: 0,
    paddingSM: 0,
    paddingXS: 0,
  },
  Radio: {
    controlHeightLG: 48,
    borderRadiusLG: 6,
    buttonPaddingInline: 8,
    colorBgContainer: 'var(--color-bg-box)',
  },
  Table: {
    colorBgContainer: 'transparent',
  },
  Divider: {
    colorSplit: 'var(--border-color)',
  },
  Input: {
    colorBgContainer: 'var(--color-bg-box)',
    colorBgContainerDisabled: 'var(--color-bg-box)',
    activeBg: 'var(--color-bg-box)',
    hoverBg: 'var(--color-bg-box)',
  },
  DatePicker: {
    controlHeightLG: INPUT_HEIGHT_LG,
    colorBgContainer: 'var(--color-bg-box)',
    colorBgContainerDisabled: 'var(--color-bg-box)',
    activeBg: 'var(--color-bg-box)',
    hoverBg: 'var(--color-bg-box)',
    colorBorder: 'var(--input-border-color)',
  },
};

export const commonComponentDarkTheme: ThemeConfig['components'] = {
  Layout: {
    ...commonComponentTheme.Layout,
    lightSiderBg: 'var(--color-bg-box)',
  },
  Menu: {
    ...commonComponentTheme.Menu,
    itemBg: 'var(--color-bg-box)',
    itemSelectedColor: '#000000',
  },
  Card: {
    ...commonComponentTheme.Card,
    colorBgContainer: 'var(--color-bg-box)',
  },
  Button: {
    ...commonComponentTheme.Button,
    primaryColor: 'var(--colorReverse)',
    defaultBg: 'transparent',
    defaultGhostColor: 'var(--colorReverse)',
  },
  Select: {
    ...commonComponentTheme.Select,
    selectorBg: 'var(--color-bg-box)',
    colorBorder: 'var(--color-border)',
  },
  Drawer: {
    ...commonComponentTheme.Drawer,
    colorBgElevated: 'var(--color-bg-box)',
  },
};
