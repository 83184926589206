import { Breadcrumb } from '@monorepo/react-components';
import { PagePath } from '@monorepo/types';
import { useLocation } from 'react-router-dom';
import BreadcrumbLink from './breadcrumbs-link';

export const BreadcrumbElements: Record<
  PagePath,
  {
    title: string;
    disabled?: boolean;
  }
> = {
  [PagePath.Overview]: {
    title: 'Account Overview',
  },
  [PagePath.Orders]: {
    title: 'Orders',
  },
  [PagePath.Profile]: {
    title: 'Profile',
  },
  [PagePath.Affiliation]: {
    title: 'Affiliation',
  },
  [PagePath.Partners]: {
    title: 'Partners',
  },
  [PagePath.TradingPlan]: {
    title: 'Trading Plan',
  },
  [PagePath.Achievements]: {
    title: 'Achievements',
  },
  [PagePath.Autochartist]: {
    title: 'Autochartist',
  },
  [PagePath.AssetsSpecifications]: {
    title: 'Assets Specifications',
  },
  [PagePath.Login]: {
    title: 'Login',
  },
  [PagePath.Register]: {
    title: 'Register',
  },
  [PagePath.ForgotPassword]: {
    title: 'Forgot Password',
  },
  [PagePath.NewAccount]: {
    title: 'New Account',
  },
  [PagePath.NewAddon]: {
    title: 'New Addon',
  },
  [PagePath.HubDownloads]: {
    title: 'Hub Downloads',
  },
  [PagePath.HubVod]: {
    title: 'Hub VOD',
  },
  [PagePath.AffiliateKit]: {
    title: 'Affiliate Kit',
  },
  [PagePath.Root]: {
    title: 'Trader',
  },
  ['/thank-you' as PagePath]: {
    title: 'Thank You',
  },
  ['/kyc' as PagePath]: {
    title: 'Verification',
  },
  ['/welcome' as PagePath]: {
    title: 'Welcome',
  },
};

export const findBreadCrumb = (path?: string): PagePath | undefined => {
  return Object.keys(BreadcrumbElements).find(
    (key) => key === '/' + path
  ) as PagePath;
};

const Breadcrumbs = () => {
  const location = useLocation();
  const items = location.pathname.split('/').map((path) => {
    const breadCrumb = findBreadCrumb(path);
    let title = path;

    if (breadCrumb) {
      title = BreadcrumbElements[breadCrumb].title;
    }

    return {
      path,
      title,
    };
  });

  return (
    <Breadcrumb
      itemRender={(currentPath, _, fullPath) => (
        <BreadcrumbLink currentPath={currentPath} fullPath={fullPath} />
      )}
      items={items}
    />
  );
};
export default Breadcrumbs;
