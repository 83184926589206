import { AccountType } from '@monorepo/types';
import {
  Flex,
  TextComponent,
  Toggle,
  ToggleProps,
} from '@monorepo/react-components';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import './account-type-toggle.scss';

type Props = Omit<ToggleProps<AccountType>, 'options'>;

const AccountTypeToggle: FunctionComponent<Props> = (props) => {
  const accountTypeOptions = Object.entries(AccountType).map(
    ([key, value]) => ({
      value,
      title: (
        <Flex gap={5} align="center">
          <div
            className={classNames('account-type-toggle__marker', {
              [`${value}`]: true,
            })}
          />
          <TextComponent level={7} responsive={false}>
            {key}
          </TextComponent>
        </Flex>
      ),
    })
  );
  return <Toggle options={accountTypeOptions} {...props} />;
};

export default AccountTypeToggle;
