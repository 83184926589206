import CrudService from '../core/crud-service';
import {
  Account,
  AccountDailyPerformance,
  AccountStats,
  ConsistencyRules,
  AccountBalanceChart,
  AccountBalance,
  ConsistencyRuleResult,
  ConsistencyRulesValues,
  MidnightValue,
} from '@monorepo/types';
import { BaseResponse } from '../core/base-entity-service';

export default class AccountService extends CrudService {
  static override route = 'account';

  async getConsistencyRules(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<Record<ConsistencyRules, ConsistencyRuleResult>>
      >(`${this.path}/${accountId}/consistency-rules`);

      return response.data;
    } catch (e) {
      console.error(
        `Failed getting account consistency rules: ${accountId}`,
        e
      );
      throw e;
    }
  }

  async getConsistencyRule(accountId: string, rule: ConsistencyRules) {
    try {
      const response = await this.httpService.get<
        BaseResponse<ConsistencyRulesValues>
      >(`${this.path}/${accountId}/consistency-rule/${rule}`);

      return response.data;
    } catch (e) {
      console.error(
        `Failed getting account consistency rule: ${accountId}, rule: ${rule}`,
        e
      );
      throw e;
    }
  }

  async getStats(accountId: string) {
    try {
      const response = await this.httpService.get<BaseResponse<AccountStats>>(
        `${this.path}/${accountId}/stats`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting account stats: ${accountId}`, e);
      throw e;
    }
  }

  async getDailyPerformance(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<AccountDailyPerformance>
      >(`${this.path}/${accountId}/daily-performance`);

      return response.data;
    } catch (e) {
      console.error(`Failed getting daily performance: ${accountId}`, e);
      throw e;
    }
  }

  async getBalanceChart(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<AccountBalanceChart[]>
      >(`${this.path}/${accountId}/balance-chart`);

      return response.data;
    } catch (e) {
      console.error(`Failed getting account balance chart: ${accountId}`, e);
      throw e;
    }
  }

  async getBalance(accountId: string) {
    try {
      const response = await this.httpService.get<BaseResponse<AccountBalance>>(
        `${this.path}/${accountId}/balance`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting account balance: ${accountId}`, e);
      throw e;
    }
  }

  async getMidnightHistory(accountId: string) {
    try {
      const response = await this.httpService.get<
        BaseResponse<MidnightValue[]>
      >(`${this.path}/${accountId}/midnight-history`);

      return response.data;
    } catch (e) {
      console.error(`Failed getting  midnight history: ${accountId}`, e);
      throw e;
    }
  }

  async sync(accountId: string) {
    try {
      await this.httpService.post(`${this.path}/${accountId}/sync`);

      return true;
    } catch (e) {
      console.error(`Failed syncing account: ${accountId}`, e);
      throw e;
    }
  }

  async getByOrderUniqId(orderUniqId: string) {
    try {
      const response = await this.httpService.get<BaseResponse<Account>>(
        `${this.path}/order/${orderUniqId}`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting account: ${orderUniqId}`, e);
      throw e;
    }
  }

  async getByTradingSystemAccount(tsAccount: string) {
    try {
      const response = await this.httpService.get<BaseResponse<Account>>(
        `${this.path}/ts/${tsAccount}`
      );

      return response.data;
    } catch (e) {
      console.error(`Failed getting account: ${tsAccount}`, e);
      throw e;
    }
  }

  async selectPartner(orderUniqId: string, partnerId: string) {
    try {
      const response = await this.httpService.post<
        { orderUniqId: string; partnerId: string },
        BaseResponse<Account>
      >(`${this.path}/partner-select`, {
        orderUniqId,
        partnerId,
      });

      return response.data;
    } catch (e) {
      console.error(
        `Failed selecting partner: ${partnerId}, for order: ${orderUniqId}`,
        e
      );
      throw e;
    }
  }
}
