import { App, Button, Result } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import { PagePath } from '@monorepo/types';
import { AppLoadingSpinner } from '../../components/spinners';

const ConfirmPage = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { confirmCode } = useParams<{ confirmCode: string }>();
  const {
    dataStore: { userStore },
  } = useStore();

  useEffect(() => {
    const init = async () => {
      if (confirmCode) {
        const isConfirmed = await userStore.confirm(confirmCode);

        if (isConfirmed) {
          message.success({
            content:
              'Successfully Confirmed user, you will be shortly redirected to login',
            duration: 3,
            onClose: () => {
              navigate(PagePath.Login);
            },
          });
        } else {
          message.error({
            content: 'Confirmation failed',
            duration: 3,
            onClose: () => {
              navigate(PagePath.Login);
            },
          });
        }
      }
    };

    init();
  }, [confirmCode]);

  if (!confirmCode) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate(PagePath.Root)}>
            Back
          </Button>
        }
      />
    );
  }

  return <AppLoadingSpinner />;
};

export default ConfirmPage;
