import {
  Flex,
  Statistic,
  TextComponent,
  Tooltip,
} from '@monorepo/react-components';
import { FunctionComponent, useState } from 'react';
import { Icons, IconsNames } from '@monorepo/icons';
import { Modal } from 'antd';
import AccountMidnightMarkTable from './account-midnight-mark-table';

import './account-midnight-mark.scss';

interface Props {
  balance: number;
  equity: number;
  accountId: string;
}

const AccountMidnightMark: FunctionComponent<Props> = ({
  balance,
  equity,
  accountId,
}) => {
  const [showMidnightHistory, setShowMidnightHistory] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOpenMidnightHistory = () => {
    setShowMidnightHistory(true);
    setShowTooltip(false);
  };

  const TooltipContent = () => (
    <Flex gap={5} vertical align="center">
      <TextComponent level={6}>Midnight Values:</TextComponent>
      <Flex gap={5} align={'center'}>
        <TextComponent level={6}>Midnight Balance:</TextComponent>{' '}
        <TextComponent level={6}>
          <Statistic value={balance} />
        </TextComponent>
      </Flex>
      <Flex gap={5} align={'center'}>
        <TextComponent level={6}>Midnight Equity:</TextComponent>
        <TextComponent level={6}>
          <Statistic value={equity} />
        </TextComponent>
      </Flex>
      <Flex gap={5} align={'center'}>
        <TextComponent level={6} noWrap>
          To View More History,{' '}
          <span
            className="account-midnight-mark__cta-btn"
            onClick={handleOpenMidnightHistory}
          >
            Click Here
          </span>
        </TextComponent>
      </Flex>
    </Flex>
  );

  return (
    <>
      <Flex align={'center'} gap={4} className="account-midnight-mark">
        <Tooltip
          open={showTooltip}
          trigger={'click'}
          onOpenChange={setShowTooltip}
          title={<TooltipContent />}
        >
          <Icons
            className={'account-midnight-mark__icon'}
            iconName={IconsNames.ModalPopup}
          />
        </Tooltip>
        <TextComponent level={8}>Midnight Mark</TextComponent>
      </Flex>
      <Modal
        onCancel={() => setShowMidnightHistory(false)}
        destroyOnClose={false}
        closable={true}
        open={showMidnightHistory}
        maskClosable={true}
        footer={null}
      >
        <AccountMidnightMarkTable accountId={accountId} />
      </Modal>
    </>
  );
};

export default AccountMidnightMark;
