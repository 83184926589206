import {
  FunctionComponent,
  MouseEvent,
  PropsWithChildren,
  useState,
} from 'react';
import {
  Button,
  Card,
  Divider,
  Flex,
  Statistic,
  TextComponent,
} from '@monorepo/react-components';
import classNames from 'classnames';
import { AccountStatus, PagePath, Program } from '@monorepo/types';
import { useNavigate } from 'react-router-dom';
import AccountItemIcon from '../account-item-icon/account-item-icon';
import DashboardAccountStatusTag from '../dashboard-templates/dashboard-common/dashboard-account-status-tag/dashboard-account-status-tag';
import { Icons, IconsNames } from '@monorepo/icons';
import { AccountWithBalance } from './account-item';
import dayjs from 'dayjs';

import './account-item.scss';

interface AccountItemAvatarProps {
  groupName?: string;
  account: AccountWithBalance;
  withStatusTag?: boolean;
}

interface AccountItemCardProps extends PropsWithChildren {
  account: AccountWithBalance;
}

interface AccountItemMobileProps {
  account: AccountWithBalance;
  program?: Program;
}

export const AccountItemAvatar: FunctionComponent<AccountItemAvatarProps> = ({
  account,
  groupName,
  withStatusTag,
}) => {
  const displayAccountID = account.tsAccount?.split('accountName').join('');

  return (
    <Flex align="center" gap={12} className="account-item-avatar">
      <AccountItemIcon
        accountType={account.type}
        disabled={account.status === AccountStatus.Disabled}
      />
      <Flex vertical className="account-item-avatar__title-container">
        <Flex align="center" justify="space-between">
          <TextComponent capitalize level={4} weight={600}>
            {groupName}
          </TextComponent>
          {withStatusTag && (
            <DashboardAccountStatusTag
              accountStatus={account.status}
              accountState={account.state}
            />
          )}
        </Flex>
        <TextComponent noWrap capitalize level={6}>
          #{displayAccountID}
        </TextComponent>
      </Flex>
    </Flex>
  );
};

export const AccountItemCard: FunctionComponent<AccountItemCardProps> = ({
  account,
  children,
}) => {
  const navigate = useNavigate();

  const onCardClick = () => {
    navigate(`${PagePath.Overview}/${account.tsAccount}`);
  };

  return (
    <Card
      className={classNames('account-item', {
        'account-item--disabled': account.status === AccountStatus.Disabled,
      })}
      onClick={onCardClick}
    >
      <div
        className={classNames('account-item__marker', {
          [`account-item__marker--${account.type}`]: true,
        })}
      />
      {children}
    </Card>
  );
};

export const AccountItemMobile: FunctionComponent<AccountItemMobileProps> = ({
  account,
  program,
}) => {
  const [showMore, setShowMore] = useState(true);

  const handleToggleShowMore = (event?: MouseEvent<any>) => {
    event?.stopPropagation();
    setShowMore((prev) => !prev);
  };

  const accountDetails = [
    {
      title: 'Size',
      value: (
        <Statistic
          value={program?.config.buyingPower}
          precision={2}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'Balance',
      value: <Statistic value={account.balance} precision={2} prefix={'$'} />,
    },
    {
      title: 'Expiration Date',
      value: account.expireDate
        ? dayjs(account.expireDate).format('MM.DD.YYYY')
        : '-',
    },
  ];

  return (
    <AccountItemCard account={account}>
      <Flex vertical gap={12} className="account-item-mobile">
        <AccountItemAvatar
          withStatusTag
          account={account}
          groupName={program?.groupName}
        />
        <Divider className="account-item-mobile__divider" />

        <Flex
          vertical
          gap={8}
          className={classNames('account-item-mobile__details', {
            opened: !showMore,
          })}
        >
          {accountDetails.map((detail, index) => (
            <Flex key={index} align="center" justify="space-between">
              <TextComponent type="secondary">{detail.title}</TextComponent>
              <TextComponent>{detail.value}</TextComponent>
            </Flex>
          ))}
        </Flex>

        <Flex
          justify="space-between"
          align="center"
          gap={4}
          onClick={handleToggleShowMore}
        >
          <TextComponent
            className={classNames('account-item-mobile__chevron', {
              opened: !showMore,
            })}
            level={7}
            responsive={false}
          >
            <Icons iconName={IconsNames.ChevronDown} />
            {showMore ? 'Show More' : 'Show Less'}
          </TextComponent>
        </Flex>

        <Button ghost className="button" type="primary">
          Dashboard
        </Button>
      </Flex>
    </AccountItemCard>
  );
};
