import { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

import './toggle.scss';

export interface ToggleProps<T> {
  options: Array<{
    title: ReactNode | string;
    value: T;
  }>;
  values?: T[];
  onChange: (value: T[]) => void;
}

export function Toggle<T>({ values = [], onChange, options }: ToggleProps<T>) {
  const handleAdd = (newValue: T) => {
    onChange([...values, newValue]);
  };

  const handleRemove = (valueToRemove: T) => {
    const filtered = values?.filter((selected) => selected !== valueToRemove);
    onChange(filtered);
  };

  return (
    <div className="the5ers-toggle">
      {options.map(({ value, title }, index) => {
        const isSelected = values.some(
          (selectedValue) => selectedValue === value
        );

        return (
          <div
            onClick={() =>
              isSelected ? handleRemove(value) : handleAdd(value)
            }
            className={classNames('the5ers-toggle__option', {
              selected: isSelected,
            })}
            key={index}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
}
