import { Flex, message, Skeleton, Steps } from 'antd';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  AnalyticsEventTypes,
  checkOrderExpiration,
  NOT_AVAILABLE,
  Order,
  OrderStatus,
  PagePath,
  PaymentProviderName,
  ProductMetaData,
  ProductType,
  SupportedCurrencies,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import BillingInfo from './steps/billing-info/billing-info';
import Summary from './steps/summary/summary';
import { StepProps } from 'antd/es/steps';
import { useNavigate, useParams } from 'react-router-dom';
import UserStore from '../../stores/data/user/user-store';
import {
  getGoogleClientId,
  getGoogleSessionId,
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import { Helmet } from 'react-helmet';
import Products from './steps/products/products';
import classNames from 'classnames';
import { Icons, IconsNames } from '@monorepo/icons';
import ProgramGroups from './steps/program-groups/program-groups';
import NewOrderStepTitle from './components/new-order-step-title/new-order-step-title';
import NewOrderProvider from './components/new-order-provider/new-order-provider';
import NewOrderStepHeadline from './components/new-order-step-headline/new-order-step-headline';
import gtmService from '../../services/analytics/analytics';
import { Button } from '@monorepo/react-components';

import './new-order.scss';

const gaId = import.meta.env.VITE_GA_ID;

const getDefaultValues = (
  userStore: UserStore,
  productType = ProductType.Program,
  extraParams: { sourceId?: string }
) => {
  const productMetaData: Partial<ProductMetaData> = {};
  if (productType === ProductType.AddOn) {
    productMetaData.accountId = extraParams.sourceId;
  }
  return {
    paymentData: {},
    productId: '',
    productType: productType,
    productMetaData: productMetaData,
    coupon: undefined,
    information: {
      isCompany: false,
      email: userStore.currentUser.email,
      firstName: userStore.currentUser.firstName || '',
      lastName: userStore.currentUser.lastName || '',
      address: userStore.currentUser.address || '',
      city: userStore.currentUser.city || '',
      zipCode: userStore.currentUser.zipCode || '',
      country: userStore.currentUser.country,
      state: userStore.currentUser.state,
      phoneNumber: userStore.currentUser.phoneNumber || '',
      googleClientId: getGoogleClientId(),
      sessionId: getGoogleSessionId(gaId),
    },
  };
};

export enum NewOrderSteps {
  Groups = 'groups',
  Product = 'product',
  Info = 'info',
  Summary = 'summary',
}

interface NewOrderProps {
  type: ProductType;
}

const defaultSteps = [
  NewOrderSteps.Product,
  NewOrderSteps.Info,
  NewOrderSteps.Summary,
];

const NewOrder: FunctionComponent<NewOrderProps> = ({ type }) => {
  const {
    dataStore: {
      userStore,
      orderStore,
      applicationConfigStore: { applicationConfig },
    },
    uiStore: { productStore },
  } = useStore();

  const navigate = useNavigate();
  const { '*': id, sourceId } = useParams<{ '*': string; sourceId: string }>();

  const shouldGroupProducts = applicationConfig.groupPrograms;

  const userSteps = shouldGroupProducts
    ? [NewOrderSteps.Groups, ...defaultSteps]
    : defaultSteps;

  const initialStep = shouldGroupProducts
    ? NewOrderSteps.Groups
    : NewOrderSteps.Product;

  const getStepIndex = (step: NewOrderSteps) => {
    return userSteps.findIndex((index) => index === step);
  };

  const defaultFormValues = getDefaultValues(userStore, type, { sourceId });

  const [currentStep, setStep] = useState(getStepIndex(initialStep));

  const form = useForm<Order>({
    mode: 'all',
    defaultValues: defaultFormValues,
  });
  const isFormValid = form.formState.isValid;
  const values = form.watch();
  const isOrderPending = id ? values.status === OrderStatus.Pending : true;
  const { loadingState, updateLoadingState } = useLoading();

  useEffect(() => {
    const init = async () => {
      try {
        const promises: Promise<any>[] = [
          productStore.fetchAllByType(type, values.productMetaData),
        ];

        updateLoadingState(LoadingState.Loading);

        if (id) {
          promises.push(orderStore.getOrder(id));
        }
        /* eslint-disable  @typescript-eslint/no-unused-vars */
        const [_, order] = await Promise.all(promises);

        if (order) {
          const isDeclined = order.status === OrderStatus.Declined;
          const isExpired = checkOrderExpiration(order.createdAt);

          if (isDeclined && isExpired) {
            message.error('Order has been expired.');
            navigate('/');
          }

          form.reset({
            ...order,
            groupName: productStore.getProgramGroupNameByProduct(
              order.productId
            ),
          });
          setStep(getStepIndex(NewOrderSteps.Summary));
        } else {
          setStep(getStepIndex(initialStep));
          form.reset(defaultFormValues);
        }
      } catch (e) {
        console.error(e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };
    init();
  }, [id]);

  const stepsStatus = {
    [NewOrderSteps.Groups]: () => {
      return shouldGroupProducts && values.groupName;
    },
    [NewOrderSteps.Product]: () => {
      return values.productId ? true : false;
    },
    [NewOrderSteps.Info]: () => {
      return Boolean(
        values.information.firstName &&
          values.information.firstName !== NOT_AVAILABLE &&
          values.information.lastName &&
          values.information.lastName !== NOT_AVAILABLE &&
          values.information.email &&
          values.information.phoneNumber &&
          values.information.zipCode &&
          values.information.country &&
          values.information.address &&
          values.information.city &&
          values.information.declaredInformationIsCorrect &&
          values.information.agreedToTerms
      );
    },
    [NewOrderSteps.Summary]: () => {
      return true;
    },
  };

  const getStepStatus = (step: NewOrderSteps) => {
    if (!isOrderPending && !(step === NewOrderSteps.Summary)) {
      return 'wait';
    }

    // Active step
    if (getStepIndex(step) === currentStep) {
      return 'process';
    }

    // Finished steps
    if (step === NewOrderSteps.Groups) {
      if (form.getValues('groupName')) {
        return 'finish';
      }
    }
    if (step === NewOrderSteps.Product) {
      if (
        (form.getValues('productId') &&
          form.formState.dirtyFields.paymentData?.currency) ||
        id
      ) {
        return 'finish';
      }
    }
    if (step === NewOrderSteps.Info) {
      if (stepsStatus[NewOrderSteps.Info]()) {
        return 'finish';
      }
    }

    return 'wait';
  };

  const onActionButtonClick = async () => {
    if (currentStep === getStepIndex(NewOrderSteps.Info)) {
      await form.trigger('information', { shouldFocus: true });

      if (!form.formState.isValid) {
        return;
      } else {
        await upsertForm();
      }
    }

    if (currentStep === getStepIndex(NewOrderSteps.Summary)) {
      if (!isFormValid) {
        await form.trigger('paymentData', { shouldFocus: true });
      } else {
        await initiatePayment();
      }

      return;
    }

    setStep(currentStep + 1);
  };

  const steps: Partial<
    StepProps & {
      content: ReactNode;
      isDone: () => string;
      type: NewOrderSteps;
    }
  >[] = [
    {
      title: <NewOrderStepTitle title="Select Funding" />,
      type: NewOrderSteps.Product,
      content: (
        <Products
          groupName={shouldGroupProducts ? values.groupName : undefined}
        />
      ),
      status: getStepStatus(NewOrderSteps.Product),
      disabled: !isOrderPending || !stepsStatus[NewOrderSteps.Groups](),
      icon: <Icons iconName={IconsNames.CoinBag} />,
    },
    {
      title: <NewOrderStepTitle title="Billing Information" />,
      type: NewOrderSteps.Info,
      content: <BillingInfo />,
      status: getStepStatus(NewOrderSteps.Info),
      disabled: !isOrderPending || !stepsStatus[NewOrderSteps.Product](),
      icon: <Icons iconName={IconsNames.CreditCardTwoBack} />,
    },
    {
      title: <NewOrderStepTitle title="Review and Pay" />,
      type: NewOrderSteps.Summary,
      content: <Summary />,
      icon: <Icons iconName={IconsNames.CheckCircle} />,
      status: getStepStatus(NewOrderSteps.Summary),
      disabled: true,
    },
  ];

  if (shouldGroupProducts) {
    steps.unshift({
      title: <NewOrderStepTitle title="Select Program" />,
      type: NewOrderSteps.Groups,
      content: (
        <ProgramGroups defaultValues={defaultFormValues as Partial<Order>} />
      ),
      disabled: !!id,
      status: getStepStatus(NewOrderSteps.Groups),
      icon: <Icons iconName={IconsNames.CertificateCheck} />,
    });
  }

  const upsertForm = async () => {
    try {
      updateLoadingState(LoadingState.Loading);
      const newOrder = form.getValues();

      const order = await orderStore.upsertByUniq(newOrder);

      if (!order) {
        return;
      }

      if (!id) {
        navigate(`${PagePath.NewAccount}/${order.uniqId}`);
      }

      form.reset(order);
    } catch (e) {
      message.error('Something went wrong.');
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const initiatePayment = async () => {
    const sendGTMEventPaymentProviderUpdated = () => {
      const discount = values.amount - values.paymentData.amount;

      const quantity = 1;

      gtmService.sendEvent({
        event: AnalyticsEventTypes.AddPaymentInfo,
        params: {
          ecommerce: {
            payment_type: values.paymentData.provider,
            currency:
              SupportedCurrencies[
                values.paymentData.currency as SupportedCurrencies
              ],
            value: values.amount,
            items: [
              {
                item_id: values.productId,
                discount,
                quantity,
                price: values.amount,
              },
            ],
          },
        },
      });
    };
    try {
      updateLoadingState(LoadingState.Loading);

      const paymentProvider = form.getValues('paymentData.provider');

      if (paymentProvider === PaymentProviderName.Credits) {
        await userStore.updateCredits();
        const creditBalance = userStore.currentUser.creditBalance || 0;
        const creditsUsed = form.getValues('paymentData.creditsUsed') || 0;

        if (creditBalance < creditsUsed) {
          message.error('You do not have enough credits');
          form.resetField('paymentData.provider');
          return;
        }
      }

      const page = await orderStore.orderPayment(
        values.uniqId,
        values.paymentData.provider
      );

      sendGTMEventPaymentProviderUpdated();

      window.location.href = page;
    } catch (e: any) {
      if (e.rc === 1) {
        message.error(
          `${e.message}, please cancel order before trying to purchase another`
        );
      } else {
        message.error('Something went wrong, Please try again later');
      }
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };
  const isCurrentStepValid = () => {
    if (
      currentStep === getStepIndex(NewOrderSteps.Groups) &&
      values.groupName
    ) {
      return false;
    }
    if (!values.productId) {
      return true;
    }

    if (
      currentStep === getStepIndex(NewOrderSteps.Product) &&
      !values.paymentData.currency
    ) {
      return true;
    }

    if (
      currentStep === getStepIndex(NewOrderSteps.Info) &&
      !stepsStatus[NewOrderSteps.Info]()
    ) {
      return true;
    }

    return false;
  };

  const isReady = loadingState === LoadingState.Loaded;
  const { isMobile } = useResponsiveness();

  const topSteps = isMobile ? steps.slice(0, currentStep + 1) : steps;
  const bottomSteps = isMobile ? steps.slice(currentStep + 1) : [];

  return (
    <NewOrderProvider
      isLoading={loadingState === LoadingState.Loading}
      form={form}
      isCurrentStepValid={isCurrentStepValid()}
      onActionClick={onActionButtonClick}
    >
      <Flex justify="center">
        <Flex
          vertical
          justify={isMobile ? 'start' : 'center'}
          className={classNames('new-account', {
            'new-account-minimal': isMobile,
          })}
        >
          <Helmet>
            <title>New Account</title>
          </Helmet>
          <Flex justify="center" gap={24}>
            {!isMobile && (
              <Button
                type="text"
                onClick={() => setStep((step) => step - 1)}
                disabled={currentStep === 0}
              >
                <Icons
                  className="new-account__back-icon"
                  iconName={IconsNames.ChevronLeft}
                />
              </Button>
            )}
            <Steps
              onChange={(step) => {
                setStep(step);
              }}
              size={isMobile ? 'small' : 'default'}
              current={currentStep}
              items={topSteps}
            />
          </Flex>
          <Flex vertical className={'body-wrapper'} align="center">
            {isReady ? (
              <>
                <NewOrderStepHeadline
                  type={steps[currentStep].type}
                  step={currentStep}
                />
                {steps[currentStep].content}
              </>
            ) : (
              <Skeleton active={true} />
            )}
          </Flex>
          {isMobile && bottomSteps.length > 0 && (
            <Steps
              onChange={(step) => {
                setStep(step + topSteps.length);
              }}
              size={'small'}
              current={currentStep + topSteps.length}
              items={bottomSteps}
            />
          )}
        </Flex>
      </Flex>
    </NewOrderProvider>
  );
};

export default NewOrder;
