import { ThemeConfigurationType } from '@monorepo/types';
import {
  commonComponentTheme,
  commonCssVars,
  commonLightCssEnv,
} from './common.theme-settings';
import { theme } from 'antd';

export const LightThemeConfigFivers: ThemeConfigurationType = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: '#1A2052',
    colorSuccess: '#179DA6',
    colorWarning: '#E9C10F',
    colorError: '#D20059',
    colorInfo: '#3240C2',
    colorLink: '#3240C2',
    colorBgBase: '#fff',
    colorTextSecondary: '#000000',
    colorText: '#000000',
    colorBgLayout: '#fafafa',
  },
  components: commonComponentTheme,
  cssVars: {
    ...commonCssVars,
    ...commonLightCssEnv,

    colorPrimary: '#1A2052',
    colorSuccess: '#179DA6',
    colorWarning: '#E9C10F',
    colorError: '#D20059',
    colorInfo: '#3240C2',
    colorLink: '#3240C2',
    colorTextSecondary: '#000000',
    colorText: '#000000',
    colorBgLayout: '#fafafa',
    colorAttention: '#1A2052',
    colorActive: '#EB0064',
    colorReverse: '#ffffff',
    colorErrorLight: 'rgb(255, 208, 228)',
    colorSecondary: '#E9C10F',
  },
};
