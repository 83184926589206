import { FunctionComponent, useEffect, useState } from 'react';
import { Card, Col, Flex, Row } from '@monorepo/react-components';
import { Account, Application, ThemeColors } from '@monorepo/types';
import ClosedPositionsTable from '../../positions/closed-positions';
import OpenPositions from '../../positions/open-positions';
import AccountBalance from '../dashboard-common/account-balance/account-balance';
import { useStore } from '../../../helpers/use-store';
import {
  LoadingState,
  useLoading,
  useResponsiveness,
} from '@monorepo/client-common';
import AccountInfo from '../dashboard-common/account-info/account-info';
import ProfitTarget from '../dashboard-common-new/progress-cards/profit-target';
import DailyLimitLevel from '../dashboard-common-new/progress-cards/daily-limit-level';
import classNames from 'classnames';
import AdvancedStatisticsTTP from './advanced-statistics-ttp/advanced-statistics-ttp';
import ProfitAndLoseCard from '../dashboard-common-new/progress-cards/profit-lose-card';
import BestRatesCards from './best-rates-cards/best-rates-cards';
import AccountRulesTtp from './account-rules-ttp/account-rules-ttp';

interface Props {
  account: Account;
}

const APPLICATION_TYPE = Application.TTP;

const TtpDashboardNew: FunctionComponent<Props> = ({ account }) => {
  const {
    dataStore: { accountStore, programStore },
  } = useStore();
  const { isDesktop, isMobile, isDesktopXxl } = useResponsiveness();
  const { loadingState, updateLoadingState } = useLoading();
  const isLoading = loadingState === LoadingState.Loading;

  const [accountDetails, setAccountDetails] = useState({
    profitAndLoss: 0,
    dailyProfitAndLoss: 0,
    balance: 0,
    credits: 0,
    equity: 0,
  });
  const id = account.tsAccount as string;

  useEffect(() => {
    const getAccountBalance = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const result = await accountStore.getBalance(id);

        setAccountDetails((details) => ({
          ...details,
          profitAndLoss: result.profitAndLoss,
          dailyProfitAndLoss: result.dailyProfitAndLoss,
          balance: result.balance as number,
          credits: result.credits as number,
          equity: result.equity as number,
        }));
      } catch (e) {
        console.error(`Failed getting account details`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    getAccountBalance();
  }, [id]);

  const showDailyMetrics = programStore.hasDailyMetrics(account.programId);

  const program = programStore.get(account.programId);

  return (
    <Flex gap={24} vertical className={'dashboard-new'}>
      <Row gutter={24}>
        <Col xs={24} xxl={17}>
          <Flex vertical gap={24}>
            {!isDesktop && <AccountInfo account={account} />}
            <div className="daily-card-container">
              <ProfitTarget
                fontColorReverse
                cardBgColor={ThemeColors.colorSuccess}
                base={program?.config.buyingPower || 0}
                profit={accountDetails.profitAndLoss}
                target={program?.config.target || 0}
                stopOut={program?.config.maxLoss || 0}
              />
              {showDailyMetrics && (
                <DailyLimitLevel
                  accountId={id}
                  showMidnight={false}
                  cardBgColor={ThemeColors.colorInfo}
                  application={APPLICATION_TYPE}
                  allowedDailyLosses={program?.config.dailyPause || 0}
                  programId={account.programId}
                  equity={accountDetails.equity}
                  baseBalanceWithCredits={
                    (program?.config?.buyingPower || 0) +
                      accountDetails.credits ||
                    program?.config?.buyingPower ||
                    0
                  }
                />
              )}
              <ProfitAndLoseCard
                isLoading={isLoading}
                profitAndLoss={accountDetails.profitAndLoss}
              />
            </div>

            <Row gutter={[24, 24]}>
              <Col xs={24} lg={16} xxl={24}>
                <Flex vertical gap={24}>
                  <AccountBalance
                    equity={accountDetails.equity}
                    balance={accountDetails.balance}
                    baseBalance={0}
                    profitAndLoss={accountDetails.profitAndLoss}
                    accountId={id}
                    application={APPLICATION_TYPE}
                  />
                  <BestRatesCards accountId={id} />
                  <OpenPositions
                    application={APPLICATION_TYPE}
                    hideSwap={true}
                    accountId={id}
                  />
                  <ClosedPositionsTable
                    application={APPLICATION_TYPE}
                    hideSwap={true}
                    accountId={id}
                  />
                </Flex>
              </Col>
              {!isDesktopXxl && (
                <Col xs={24} lg={8}>
                  <Card
                    className={classNames('side-stats', {
                      'side-stats--mobile': isMobile,
                      'side-stats--desktop': !isMobile,
                    })}
                  >
                    <Flex vertical gap={32}>
                      {isDesktop && <AccountInfo account={account} />}
                      <AccountRulesTtp account={account} />
                      <AdvancedStatisticsTTP
                        account={account}
                        dailyProfitAndLoss={accountDetails.dailyProfitAndLoss}
                      />
                    </Flex>
                  </Card>
                </Col>
              )}
            </Row>
          </Flex>
        </Col>
        {isDesktopXxl && (
          <Col xs={24} lg={7}>
            <Card
              active
              className={classNames('side-stats', {
                'side-stats--mobile': isMobile,
                'side-stats--desktop': !isMobile,
              })}
            >
              <Flex vertical gap={32}>
                <AccountInfo account={account} />
                <AccountRulesTtp account={account} />
                <AdvancedStatisticsTTP
                  account={account}
                  dailyProfitAndLoss={accountDetails.dailyProfitAndLoss}
                />
              </Flex>
            </Card>
          </Col>
        )}
      </Row>
    </Flex>
  );
};

export default TtpDashboardNew;
