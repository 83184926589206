import { UseFormReturn } from 'react-hook-form';
import {
  UserInformation,
  formPhoneValidation,
  formMaxLengthValidation,
  formNameValidation,
  Order,
  OrderAdditionalInformation,
  formUserNameValidation,
} from '@monorepo/types';
import {
  FormInput,
  FormState,
  TextComponent,
  Divider,
  Row,
  Col,
  FormDatePicker,
} from '@monorepo/react-components';
import { Icons, IconsNames } from '@monorepo/icons';
import FormCountrySelect from './form-country-select';
import dayjs, { Dayjs } from 'dayjs';

type FormType = Partial<UserInformation & Order & OrderAdditionalInformation>;
type PrefixType = keyof Pick<Order, 'information'>;

interface Props<T extends FormType> {
  fieldPrefix?: PrefixType;
  formInstance: UseFormReturn<T>;
  personalInfoTitle?: string;
  addressTitle?: string;
  showCompanyFields?: boolean;
  showBirthdate?: boolean;
  showUserName?: boolean;
}

const AccountForm = <T extends FormType>({
  formInstance,
  fieldPrefix,
  addressTitle,
  personalInfoTitle,
  showCompanyFields,
  showBirthdate,
  showUserName,
}: Props<T>) => {
  const getFieldName = (field: keyof FormType): any =>
    `${fieldPrefix ? `${fieldPrefix}.` : ''}${field}`;

  const country = formInstance.watch(getFieldName('country'));
  const shouldShowStates = ['US', 'CA', 'AU'].includes(country);

  const disableBirthdayDates = (date: Dayjs | string) => {
    const parsedDate = dayjs(date);
    const today = dayjs();
    const yearsAgo = today.subtract(18, 'year').startOf('day');

    return parsedDate.isAfter(yearsAgo);
  };

  const renderCompanyFields = () => {
    if (!showCompanyFields) {
      return null;
    }

    return (
      <>
        <Col span={24}>
          <Divider style={{ margin: 0 }} />
        </Col>
        <Col span={24}>
          <TextComponent responsive={false} level={4}>
            Company Information
          </TextComponent>
        </Col>
        <Col xs={24} lg={12}>
          <FormInput
            placeholder="Company Name"
            form={formInstance}
            controllerProps={{
              name: getFieldName('companyName'),
              rules: { required: 'Company Name is required' },
            }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <FormInput
            placeholder="Company Registration Number"
            form={formInstance}
            controllerProps={{
              name: getFieldName('companyRegistrationNumber'),
              rules: { required: 'Company Registration Number is required' },
            }}
          />
        </Col>
      </>
    );
  };

  return (
    <Row gutter={[16, 16]} className="account-form">
      <Col span={24}>
        <TextComponent responsive={false} level={4}>
          {personalInfoTitle || 'Personal Information'}
        </TextComponent>
      </Col>
      <Col xs={24} lg={12}>
        <FormInput
          props={{
            prefix: <Icons iconName={IconsNames.Person} />,
          }}
          placeholder="First Name"
          form={formInstance}
          controllerProps={{
            name: getFieldName('firstName'),
            rules: formNameValidation(),
          }}
        />
      </Col>

      <Col xs={24} lg={12}>
        <FormInput
          placeholder="Last Name"
          form={formInstance}
          props={{
            prefix: <Icons iconName={IconsNames.Person} />,
          }}
          controllerProps={{
            name: getFieldName('lastName'),
            rules: formNameValidation(),
          }}
        />
      </Col>
      {showUserName && (
        <Col xs={24} lg={12}>
          <FormInput
            placeholder="User Name"
            form={formInstance}
            controllerProps={{
              name: getFieldName('userName'),
              rules: formUserNameValidation(),
            }}
          />
        </Col>
      )}
      {showBirthdate && (
        <Col xs={24} lg={12}>
          <FormDatePicker
            size="large"
            form={formInstance}
            placeholder="Birthday"
            picker="date"
            disabledDate={disableBirthdayDates}
            controllerProps={{
              name: 'birthDate',
            }}
          />
        </Col>
      )}
      <Col span={24}>
        <FormInput
          placeholder="example@example.com"
          form={formInstance}
          controllerProps={{
            name: getFieldName('email'),
            disabled: true,
          }}
        />
      </Col>
      <Col span={24}>
        <FormInput
          placeholder="Phone"
          form={formInstance}
          controllerProps={{
            name: getFieldName('phoneNumber'),
            rules: formPhoneValidation(),
          }}
        />
      </Col>
      {renderCompanyFields()}
      <Col span={24}>
        <Divider style={{ margin: 0 }} />
      </Col>
      <Col span={24}>
        <TextComponent responsive={false} level={4}>
          {addressTitle || 'Address'}
        </TextComponent>
      </Col>
      <Col xs={24}>
        <FormInput
          placeholder="Street address"
          form={formInstance}
          controllerProps={{
            name: getFieldName('address'),
            rules: {
              required: 'Address name is required',
              maxLength: formMaxLengthValidation(
                'Address name maximum length exceeded'
              ),
            },
          }}
        />
      </Col>

      <Col span={12}>
        <FormInput
          placeholder="City"
          form={formInstance}
          controllerProps={{
            name: getFieldName('city'),
            rules: {
              required: 'City name is required',
              maxLength: formMaxLengthValidation(
                'city name maximum length Exceeded'
              ),
            },
          }}
        />
      </Col>
      <Col span={12}>
        <FormInput
          placeholder="Post Code"
          form={formInstance}
          controllerProps={{
            name: getFieldName('zipCode'),
            rules: {
              required: 'Post code name is required',
              maxLength: formMaxLengthValidation(
                'Post code name maximum length exceeded'
              ),
            },
          }}
        />
      </Col>
      <Col xs={24}>
        <FormCountrySelect
          placeholder="Country"
          form={formInstance}
          controllerProps={{
            name: getFieldName('country'),
            rules: { required: 'Country is required' },
          }}
        />
      </Col>
      {shouldShowStates && (
        <Col xs={24} md={8}>
          <FormState
            country={country}
            placeholder="Select State"
            text="State"
            form={formInstance}
            controllerProps={{
              name: getFieldName('state'),
              rules: { required: 'State is required' },
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default AccountForm;
