import React, { useEffect, useState } from 'react';
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import MainRouter from '../main-router/main-router';
import Layout from '../../components/layout/layout';
import { observer } from 'mobx-react-lite';
import Overview from '../../features/overview/overview';
import NewOrder from '../../features/new-order/new-order';
import Orders from '../../features/orders/orders';
import Profile from '../../features/profile/profile';
import ThankYou from '../../features/thank-you/thank-you';
import { useStore } from '../../helpers/use-store';
import Partners from '../../features/partners/partners';
import Affiliation from '../../features/affiliation/affiliation';
import Achievements from '../../features/achievements/achievements';
import DashboardContainer from '../../features/dashboard-templates/dashboard-container';
import {
  PagePath,
  FeatureFlagNames,
  Pages,
  ProductType,
  StaticPagesUrls,
} from '@monorepo/types';
import { useFeatureFlags } from '../../hooks/use-feature-flags';
import {
  init,
  browserTracingIntegration,
  replayIntegration,
  setUser,
} from '@sentry/react';
import { compact } from 'lodash';
import { StaticPage } from '@monorepo/react-components';
import PreviewInvoiceModal from '../../features/orders/preview-invoice-modal/preview-invoice-modal';
import { LaunchDarkly } from '../../components/launch-darkly/launch-darkly';
import LiveChat from '../../features/live-chat/live-chat';
import { AppLoadingSpinner } from '../../components/spinners';
import Welcome from '../../features/welcome/welcome';

const isProduction = import.meta.env.VITE_APP_ENV === 'production';

if (isProduction) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const LoggedIn = () => {
  const {
    dataStore: {
      programStore,
      realTimeStore,
      userStore,
      applicationConfigStore,
      groupStore,
    },
  } = useStore();
  const { isFeatureEnabled } = useFeatureFlags();
  const [isAppReady, setAppReady] = useState(false);
  useEffect(() => {
    const init = async () => {
      realTimeStore.initSocket();
      userStore.registerToRealTimeData();
      await Promise.all([
        applicationConfigStore.get(),
        programStore.fetchAll(),
        userStore.getUserCoupons(),
        userStore.getUserContracts(),
        groupStore.fetchAll(),
      ]);

      if (isProduction) {
        setUser({ email: userStore.currentUser.email });
      }

      setAppReady(true);
    };

    init();
  }, []);

  useEffect(() => {
    localStorage.removeItem('initialUrl');
  }, []);

  const affiliationFeatureEnabled = isFeatureEnabled(
    FeatureFlagNames.DisableAffiliation
  );

  const paymentsEnabled =
    isFeatureEnabled(FeatureFlagNames.DisablePayments) &&
    !applicationConfigStore.applicationConfig.isBlacklisted;

  const sidebarRoutesMap: Record<Pages, JSX.Element | null> = {
    [Pages.Overview]: (
      <Route
        key={Pages.Overview}
        path={PagePath.Overview}
        element={<Overview />}
      />
    ),
    [Pages.Orders]: (
      <Route key={Pages.Orders} path={PagePath.Orders} element={<Orders />} />
    ),
    [Pages.Profile]: (
      <Route
        key={Pages.Profile}
        path={`${PagePath.Profile}/:activeTab?`}
        element={<Profile />}
      />
    ),
    [Pages.Affiliation]: affiliationFeatureEnabled ? (
      <Route
        key={Pages.Affiliation}
        path={PagePath.Affiliation}
        element={<Affiliation />}
      />
    ) : null,
    [Pages.Achievements]: (
      <Route
        key={Pages.Achievements}
        path={PagePath.Achievements}
        element={<Achievements />}
      />
    ),
    [Pages.Autochartist]: (
      <Route
        key={Pages.Autochartist}
        path={PagePath.Autochartist}
        element={<StaticPage src={StaticPagesUrls['autochartist']} />}
      />
    ),
    [Pages.AssetsSpecifications]: (
      <Route
        key={Pages.AssetsSpecifications}
        path={PagePath.AssetsSpecifications}
        element={<StaticPage src={StaticPagesUrls['assets-specifications']} />}
      />
    ),
    [Pages.HubDownloads]: (
      <Route
        key={Pages.HubDownloads}
        path={PagePath.HubDownloads}
        element={<StaticPage src={StaticPagesUrls['hub-downloads']} />}
      />
    ),
    [Pages.HubVod]: (
      <Route
        key={Pages.HubVod}
        path={PagePath.HubVod}
        element={<StaticPage src={StaticPagesUrls['hub-vod']} />}
      />
    ),
    [Pages.TradingPlan]: (
      <Route
        key={Pages.TradingPlan}
        path={PagePath.TradingPlan}
        element={<StaticPage src={StaticPagesUrls['trading-plan']} />}
      />
    ),
    [Pages.AffiliateKit]: (
      <Route
        key={Pages.AffiliateKit}
        path={PagePath.AffiliateKit}
        element={<StaticPage src={StaticPagesUrls['affiliate-kit']} />}
      />
    ),
  };

  const { sidebar } = applicationConfigStore.applicationConfig;

  const sidebarRoutes = compact(
    sidebar.map(({ page }) => sidebarRoutesMap[page])
  );

  return isAppReady ? (
    <Router>
      <LaunchDarkly>
        <LiveChat />
        <Layout>
          <Routes>
            <Route path="/" element={<MainRouter />} />
            {sidebarRoutes}
            <Route path="/overview/:id" element={<DashboardContainer />} />
            {!userStore.verificationRequired && paymentsEnabled && (
              <Route
                path="/new-account/*"
                element={<NewOrder type={ProductType.Program} />}
              />
            )}
            {!userStore.verificationRequired && (
              <Route
                path="/new-addon/:sourceId/*"
                element={<NewOrder type={ProductType.AddOn} />}
              />
            )}
            <Route path="/achievements" element={<Achievements />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/thank-you/:id" element={<ThankYou />} />
            <Route path="/partners/:id" element={<Partners />} />

            <Route path={PagePath.Orders} element={<Orders />}>
              <Route
                path={`${PagePath.Orders}/:id`}
                element={<PreviewInvoiceModal />}
              />
            </Route>
            <Route path="/coupons/:org/:name" element={<Orders />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Layout>
      </LaunchDarkly>
    </Router>
  ) : (
    <AppLoadingSpinner />
  );
};

export default observer(LoggedIn);
