import OverviewEmpty from '../overview/overview-empty';
import PageContainer from '../page-container/page-container';

const Welcome = () => {
  return (
    <PageContainer helmet="Welcome">
      <OverviewEmpty />
    </PageContainer>
  );
};

export default Welcome;
