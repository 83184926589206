import { ThemeConfigurationType } from '@monorepo/types';
import {
  commonComponentDarkTheme,
  commonComponentTheme,
  commonCssVars,
  commonDarkCssEnv,
} from './common.theme-settings';
import { theme } from 'antd';

export const DarkThemeConfigFivers: ThemeConfigurationType = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: '#ffc806',
    colorSuccess: '#2DC0CA',
    colorWarning: '#FFD727',
    colorError: '#FF579E',
    colorInfo: '#AAB2FF',
    colorLink: '#256BE4',
  },
  components: { ...commonComponentTheme, ...commonComponentDarkTheme },
  cssVars: {
    ...commonCssVars,
    ...commonDarkCssEnv,

    colorPrimary: '#ffc806',
    colorSuccess: '#2DC0CA',
    colorWarning: '#FFD727',
    colorError: '#FF579E',
    colorInfo: '#AAB2FF',
    colorLink: '#256BE4',
    colorAttention: '#E9C10F',
    colorActive: '#EB0064',
    colorReverse: '#ffffff',
    colorErrorLight: 'rgb(255, 208, 228)',
    colorSecondary: '#FFD727',
  },
};
