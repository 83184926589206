import { Icons, IconsNames } from '@monorepo/icons';
import {
  Button,
  Flex,
  TextComponent,
  Tooltip,
} from '@monorepo/react-components';
import ThemeSwitch from '../../../features/theme-switch/theme-switch';
import { FunctionComponent } from 'react';
import { useResponsiveness } from '@monorepo/client-common';

interface Props {
  facebookLink?: string;
  instagramLink?: string;
  twitterLink?: string;
  discordLink?: string;
  youtubeLink?: string;
  showDiscordLink?: boolean;
}

const NavigationMenuFooter: FunctionComponent<Props> = ({
  discordLink,
  youtubeLink,
  facebookLink,
  instagramLink,
  twitterLink,
  showDiscordLink,
}) => {
  const { isDesktopXxl } = useResponsiveness();

  const renderFollowUsLinks = () => {
    const socialButtons = [
      {
        icon: IconsNames.Facebook,
        url: facebookLink,
      },
      {
        icon: IconsNames.Twitter,
        url: twitterLink,
      },
      {
        icon: IconsNames.Instagram,
        url: instagramLink,
      },
    ].filter((social) => social.url);

    if (!socialButtons.length) {
      return null;
    }

    return (
      <>
        <TextComponent level={8} responsive={false}>
          Follow Us
        </TextComponent>
        <Flex gap={24} align="center">
          {socialButtons.map(({ icon, url }) => (
            <Button
              key={url}
              target="_blank"
              href={url}
              icon={<Icons className="icon" iconName={icon} />}
            />
          ))}
        </Flex>
      </>
    );
  };

  const renderLearningLinks = () => {
    if (!discordLink && !youtubeLink) {
      return null;
    }
    return (
      <>
        {youtubeLink && (
          <Button
            target="_blank"
            href={youtubeLink}
            icon={<Icons iconName={IconsNames.Youtube} />}
          >
            Youtube
          </Button>
        )}

        {discordLink && (
          <Tooltip
            trigger="hover"
            title={
              !showDiscordLink
                ? 'Join our exclusive Discord community by purchasing an account'
                : ''
            }
          >
            <Button
              disabled={!showDiscordLink}
              target="_blank"
              href={discordLink}
              icon={<Icons iconName={IconsNames.Discord} />}
            >
              Discord
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <Flex vertical className="navigation-menu__footer" gap={12}>
      {renderLearningLinks()}
      {renderFollowUsLinks()}
      {!isDesktopXxl && <ThemeSwitch />}
    </Flex>
  );
};
export default NavigationMenuFooter;
